import { IconProps } from './type';

export const IconYoutube = ({ color = '#F9FAFB' }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" fill="none" viewBox="0 0 20 14">
    <path
      fill={color}
      d="M19.085 2.42A2.383 2.383 0 0017.41.731C15.929.333 10 .333 10 .333s-5.93 0-7.409.4A2.383 2.383 0 00.915 2.418C.519 3.908.519 7.013.519 7.013s0 3.106.396 4.594c.218.822.86 1.441 1.676 1.66 1.48.4 7.409.4 7.409.4s5.93 0 7.409-.4a2.347 2.347 0 001.676-1.66c.396-1.488.396-4.594.396-4.594s0-3.105-.396-4.594zM8.061 9.832v-5.64l4.956 2.82-4.956 2.82z"
    />
  </svg>
);
