export const GA_TRACKING_ID = process.env.GA_TRACKING_ID;

declare const window: Window &
  typeof globalThis & {
    gtag: (key: string, value: string, another: object) => void;
    dataLayer: {
      push: (params: object) => void;
    };
  };

export const pageview = (url: string) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

export const event = (params: object) => {
  window.dataLayer?.push(params);
};
