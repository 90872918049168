import { IHeaderBanner } from './types';
import { useMemo } from 'react';

export const HeaderBanner = ({
  banners,
  defaultColor,
  className,
  isFromRecommendation = false,
}: IHeaderBanner) => {
  const background = useMemo(() => {
    const classes = ['w-full h-full bg-blend-overlay bg-no-repeat bg-cover bg-left sm:bg-top'];
    const hasBanner = Object.values(banners).find(banner => banner);

    if (hasBanner) {
      if (!banners.sm || !banners.md || !banners.lg)
        classes.push(defaultColor ? `bg-[${defaultColor}]` : 'bg-primary');

      if (banners.sm)
        classes.push(`bg-transparent bg-[url('${banners.sm || '/assets/img/header_home_sm.png'}')]`);
      if (banners.md)
        classes.push(`md:bg-transparent md:bg-[url('${banners.md || '/assets/img/header_home_sm.png'}')]`);
      if (banners.lg)
        classes.push(`lg:bg-transparent lg:bg-[url('${banners.lg || '/assets/img/header_home_sm.png'}')]`);
    } else {
      if (isFromRecommendation && classes.length <= 1) {
        classes.push(`bg-[url('/assets/img/banner_recommendation.jpg')]`);
      } else {
        classes.push(defaultColor ? `bg-[${defaultColor}]` : 'bg-primary');
      }
    }

    return classes.join(' ');
  }, [banners, defaultColor, isFromRecommendation]);

  return (
    <section
      className={className || 'flex relative z-0 h-[200px] sm:h-[200px]'}
      data-testid="header-banner-container"
    >
      <div className="absolute top-0 left-0 right-0 h-[220px] sm:h-[300px]">
        <div className={background} data-testid="header-banner-background" />
      </div>
    </section>
  );
};
