const spinner = [
  'inline-block',
  'border-r-transparent',
  'border-info-500',
  'border-4',
  'rounded-full',
  'animate-spin',
  'w-8',
  'h-8',
  'mt-2',
  'w-[20px] h-[20px] border-white border-opacity-80 border-2',
].join(' ');

export { spinner };
