import React from 'react';
import { Button, Icon, Modal, Text } from '@solides/management-util-ui';

const CancelButton = ({ onClick }) => (
  <Button
    size="md"
    className="flex-1 justify-center"
    variant="outlined"
    onClick={onClick}
    data-cy="modal-curriculumUpdate-cancel"
  >
    Cancelar
  </Button>
);

const ConfirmButton = ({ onClick }) => (
  <Button
    size="md"
    color="primary"
    className="flex-1 justify-center"
    variant="contained"
    onClick={onClick}
    data-cy="modal-curriculumUpdate-acept"
  >
    Revisar
  </Button>
);

const Title = () => (
  <div className="flex flex-col items-center gap-y-5 w-full">
    <Icon face="offline_bolt" size="xl" variant="outlined" className="text-primary text-[54px]" />
    <Text variant="h5" className="text-center">
      Você está a 1 passo
      <br />
      da sua candidatura
    </Text>
  </div>
);

export const ModalUpdateCurriculum = ({ show, onClose, onConfirm }) => (
  <Modal
    size="sm"
    show={show}
    title={<Title />}
    onClose={onClose}
    showCloseIcon={false}
    cancelButton={<CancelButton onClick={onClose} />}
    confirmButton={<ConfirmButton onClick={onConfirm} />}
  >
    <Text variant="body1" className="text-center text-gray-800 mb-5">
      Para concluir sua candidatura você deverá revisar seu currículo e preencher os campos obrigatórios
      exigidos pela vaga. Deseja revisar seu currículo?
    </Text>
  </Modal>
);
