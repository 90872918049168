import { useEffect, useRef } from 'react';

export const useScrollToElement = (id: string) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === id) {
      const scrollToSection = () => {
        if (sectionRef.current) sectionRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      setTimeout(() => scrollToSection(), 1000);
    }
  }, []);

  return sectionRef;
};
