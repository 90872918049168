export { IconFacebook } from './icons/facebook';
export { IconFacebookF } from './icons/facebookF';
export { IconInstagram } from './icons/instagram';
export { IconLinkedin } from './icons/linkedin';
export { IconTwitter } from './icons/twitter';
export { IconGlassdoor } from './icons/glassdoor';
export { IconYoutube } from './icons/youtube';
export { IconPlayer } from './icons/player';
export { IconJobs } from './icons/jobs';
export { IconSolides } from './icons/solides';
