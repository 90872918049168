import { Loading as Animation } from '@solides/management-util-ui';

import { container } from './styles';

export const Loading = () => (
  <div
    className={container}
    style={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Animation />
  </div>
);
