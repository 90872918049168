/* eslint-disable no-param-reassign */
import axios, { AxiosResponse } from 'axios';
import cookies from 'utils/cookies';

const api = axios.create({
  baseURL: process.env.API_HOST,
});

const apiV2 = axios.create({
  baseURL: process.env.API_V2_HOST,
});

const apiApplicantsV3 = axios.create({
  baseURL: process.env.API_APPLICANTS_V3_HOST,
});

apiApplicantsV3.interceptors.request.use(config => {
  const idToken = cookies.get('idToken') || '';
  delete config.headers['jToken'];
  if (idToken) {
    config.headers['Authorization'] = `Bearer ${idToken}`;
  }

  return config;
});

apiApplicantsV3.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

apiV2.interceptors.request.use(config => {
  const jToken = cookies.get('token') || '';
  delete config.headers['Authorization'];

  if (jToken) {
    config.headers['jToken'] = jToken;
  }

  return config;
});

apiV2.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.request.use(config => {
  config.headers['x-api-key'] = process.env.API_KEY;
  return config;
});

export const getApiApplicants = (isApplicantsV3Enabled?: boolean) =>
  !!isApplicantsV3Enabled ? apiApplicantsV3 : apiV2;

export { api, apiV2, apiApplicantsV3 };
