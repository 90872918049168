import useSWR from 'swr';
import { apiApplicantsV3, apiV2 } from 'services/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFetchV2<Data = any, Error = any>(
  url: string | object,
  options: object = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnMount: true,
  },
  isApplicantsV3 = false
) {
  const { data, error, isLoading } = useSWR<Data, Error>(
    url,
    async url => {
      const api = isApplicantsV3 ? apiApplicantsV3 : apiV2;
      const response = await api.get(url);
      return response.data;
    },
    options
  );

  return { data, error, isLoading };
}
