import { useEffect, useMemo, useState } from 'react';

import useStore from 'store';
import { api } from 'services/api';
import { handleToAuth } from 'utils/user';
import { Icon, Toast } from '@solides/management-util-ui';

interface FavoriteVacancyProps {
  vacancyId: number;
}

export const FavoriteVacancy = ({ vacancyId }: FavoriteVacancyProps) => {
  const [active, setActive] = useState<boolean>(false);
  const [isOpenToast, setIsOpenToast] = useState<boolean>(false);

  const { user, isAuth, updatingFavorites } = useStore(store => store.session);

  const isFavorite = useMemo(
    () => user.vacancies.favorites.includes(vacancyId),
    [vacancyId, user.vacancies.favorites]
  );

  useEffect(() => {
    setActive(isFavorite);
  }, [isFavorite]);

  const updatingFavorite = async () => {
    try {
      await api.post('/portal-vacancy/bookmark', {
        vacancyId,
        userId: user.id,
      });

      updatingFavorites(vacancyId);
    } catch (err) {
      console.log(err);
      setActive(state => !state);
    }
  };

  const changeStatusFavorite = () => {
    if (isAuth) {
      setActive(state => !state);
      updatingFavorite();
    } else {
      setIsOpenToast(true);
    }
  };

  if (user?.vacancies?.applications?.includes(vacancyId)) return null;

  return (
    <>
      <button
        type="button"
        className="text-primary-500 h-6"
        onClick={changeStatusFavorite}
        data-testid="button-favorite-vacancy"
        data-cy="button-favorite-vacancy"
        title={active ? 'Desfavoritar vaga' : 'Favoritar vaga'}
        aria-label="Botão para salvar/remover uma vaga dos favoritos"
        aria-pressed={active}
      >
        <Icon face={active ? 'favorite' : 'favorite_border'} size="md" />
      </button>
      <Toast
        closeButton={false}
        color="info"
        renderTime={5000}
        isOpen={isOpenToast}
        title="Deseja favoritar a vaga?"
        description="Para favoritar e gerenciar suas vagas, é necessário acessar sua conta."
        confirmButton={{ label: 'Entrar', onClick: handleToAuth }}
      />
    </>
  );
};
