import { Address } from './address';

export type SalaryType = 'range' | 'simple';
export type JobType = 'presencial' | 'remoto' | 'hibrido';

export interface VacancySalary {
  type: SalaryType;
  initialRange: number | null;
  finalRange: number | null;
}

export interface Requirements {
  id: number;
  name: string;
  level?: number | null;
}

export enum VacancyType {
  mixed = 'mista',
  external = 'externa',
  internal = 'interna',
}

export interface Vacancy {
  id: number;
  title: string;
  description: string;
  companyName: string;
  createdAt: string;
  slug: string;
  state?: {
    id: number;
    name: string;
    code: string;
  };
  city?: {
    id: number;
    name: string;
    state_id: number;
  };
  country?: {
    id: number;
    name: string;
    code: string;
  };
  address?: Address;
  currentState: 'concluido' | 'em_andamento';
  showModality?: boolean;

  homeOffice: boolean;
  companyLogo: string | null;
  redirectLink: string;
  jobType: JobType;

  pcdOnly: boolean;
  peopleWithDisabilities: boolean;

  salary: VacancySalary;
  openPositions: number;
  availablePositions: number;
  education: Requirements[];
  language: Requirements[];
  hardSkills: Requirements[];
  benefits: Requirements[];
  seniority: Requirements[];
  recruitmentContractType: Requirements[];
  affirmative: Requirements[];
  occupationAreas: Requirements[];
  requiredFields: string | null | undefined;
  isHiddenJob: boolean;
  type?: VacancyType;
  receivingResume: boolean;
}

export interface QueryVacancy {
  title?: string;
  slug?: string;
  locations?: string;
  page?: string;
  salary?: string;
  benefits?: string;
  contractsType?: string;
  jobPosition?: string;
  jobsType?: string;
  seniorities?: string;
}

export interface GetParamsSearchVacancy extends Omit<QueryVacancy, 'locations' | 'salary' | 'page'> {
  locations: string;
  take: number;
  page: number;
  minSalary?: number;
  maxSalary?: number;
}

export interface VacancySimple {
  id: number;
  title: string;
  companyName: string;
  slug: string;
}
