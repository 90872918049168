import { Icon } from '@solides/management-util-ui';
import { NotificationAction } from 'types/notification';
interface IconProps {
  action: NotificationAction;
}

export const NotificationIcon = ({ action }: IconProps) => {
  if (action === 'closeVacancy') {
    return (
      <span className="flex w-10 h-10 rounded-xl bg-info/20">
        <Icon face="info" className="m-auto text-info" />
      </span>
    );
  }

  if (action === 'phaseAdvance') {
    return (
      <span className="flex w-10 h-10 rounded-xl bg-success/20">
        <Icon face="sentiment_very_satisfied" className="m-auto text-success" />
      </span>
    );
  }

  return (
    <span className="flex w-10 h-10 rounded-xl bg-primary/20">
      <Icon face="new_releases" className="m-auto text-primary" />
    </span>
  );
};
