import { Button, Icon, Modal, Text } from '@solides/management-util-ui';

type ModalStatusSuccessProps = {
  show: boolean;
  onClose: () => void;
  onMyApplication?: () => void;
  subText: string;
  typeModal: 'talentbank' | 'vacancy';
};

const Title = () => (
  <div className="flex flex-col items-center gap-y-5 w-full">
    <Icon face="check_circle" size="xl" variant="filled" className="text-success text-[54px]" />
    <Text variant="h5" className="text-center">
      Sucesso!
    </Text>
  </div>
);

const MyApplication = ({ onClick }) => (
  <Button
    size="lg"
    className="flex-1 justify-center mb-3"
    variant="outlined"
    onClick={onClick}
    data-cy="button-my-application"
  >
    Minhas candidaturas
  </Button>
);

export const ModalStatusSuccess = ({
  show,
  onClose,
  onMyApplication,
  subText,
  typeModal,
}: ModalStatusSuccessProps) => {
  const textConfirmButton = typeModal === 'vacancy' ? 'Ok, entendi' : 'Aceitar';
  return (
    <Modal
      size="md"
      show={show}
      title={<Title />}
      onClose={onClose}
      showCloseIcon={false}
      cancelButton={typeModal === 'vacancy' && <MyApplication onClick={onMyApplication} />}
      confirmButton={
        <Button
          size="lg"
          color="primary"
          className="flex-1 justify-center mb-3"
          variant="contained"
          onClick={onClose}
          data-cy="modal-talentBank-success"
        >
          {textConfirmButton}
        </Button>
      }
    >
      <Text variant="body1" className="text-center text-gray-600 mb-5 px-8">
        {subText}
      </Text>

      <Text variant="body1" className="text-center text-gray-600 mb-5 px-8">
        <b>ATENÇÃO:</b> A Sólides não oferece consultoria para candidatura nem cobra por esses serviços.
      </Text>
    </Modal>
  );
};
