import { CreateFeatureToggle } from './types';

export const createFeaturesToggle: CreateFeatureToggle = (set, get) => ({
  features: [],
  setFeature: feature => {
    set(draft => {
      const isNewFeature = !draft.featuresToggle.features.includes(feature);

      if (isNewFeature) {
        draft.featuresToggle.features = [...draft.featuresToggle.features, feature];
      }

      return draft;
    });
  },
  isEnabled: feature => {
    return get().featuresToggle.features.includes(feature);
  },
});
