//const content = ['grid', 'grid-cols-1', 'items-start', 'gap-4', 'md:gap-6'];

const content = [
  'grid',
  'sm:grid-cols-1',
  'md:grid-cols-1',
  'lg:grid-cols-2',
  'xl:grid-cols-2',
  '2xl:grid-cols-2',
  'items-start',
  'gap-4',
  'md:gap-6',
];

const getContentByVideo = (hasVideo: boolean): string => {
  //'md:grid-cols-[1fr_400px]', 'xl:grid-cols-[1fr_500px]'
  if (hasVideo) return [...content].join(' ');

  return content.join(' ');
};

export { getContentByVideo };
