import { Modal } from '@solides/management-util-ui';
import { thumbnail } from 'utils/thumbnail';

export const ModalVideoPlayer = ({ show, title, src, onClose }) => {
  const size = typeof window !== 'undefined' && window?.innerHeight ? window.innerHeight - 300 : 400;

  return (
    <Modal show={show} size="xl" onClose={onClose} showCloseIcon horizontalLine={false}>
      <iframe
        width="100%"
        height={size < 300 ? 450 : size}
        frameBorder="0"
        title={title}
        src={thumbnail(src, false)}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Modal>
  );
};
