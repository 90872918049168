import { z } from 'zod';

export const modalRecommendationSchema = (blocks: string[]) =>
  z
    .object({
      hasRecommendation: z.string(),
      name: z.union([
        z.literal(''),
        z
          .string()
          .min(2, 'Nome inválido')
          .max(80, 'Máximo de caracteres pemitidos: 80')
          .regex(
            /^[a-záàâãéèêíïóôõöúçñü]+[-'s]?[a-záàâãéèêíïóôõöúçñü ]+$/i,
            'Este campo aceita apenas letras'
          )
          .trim()
          .pipe(z.string().includes(' ', { message: 'Digite pelo menos um sobrenome' }).trim()),
      ]),
      email: z.union([
        z.literal(''),
        z.string().email('E-mail inválido').max(100, 'Máximo de caracteres pemitidos: 100').optional(),
      ]),
    })
    .superRefine(({ hasRecommendation, name, email }, ctx) => {
      if (hasRecommendation === 'sim') {
        if (!name) ctx.addIssue({ code: 'custom', message: 'Informe o seu nome', path: ['name'] });
        if (!email)
          ctx.addIssue({ code: 'custom', message: 'Informe o e-mail do colaborador', path: ['email'] });
        if (blocks.includes(email.trim()))
          ctx.addIssue({
            code: 'custom',
            message: 'E-mail indisponível. Tente contactar outra pessoa',
            path: ['email'],
          });
      }
    });
