import { Button, Icon, Modal, Text } from '@solides/management-util-ui';
import { Spinner } from '../Spinner';

const ReviewButton = ({ onClick, isLoading = false }) => (
  <Button
    size="md"
    className="flex-1 justify-center"
    variant="outlined"
    onClick={onClick}
    data-cy="review-button"
    disabled={isLoading}
  >
    Revisar
  </Button>
);

const SendButton = ({ onClick, isLoading = false }) => (
  <Button
    size="md"
    color="primary"
    className="flex-1 justify-center"
    variant="contained"
    onClick={onClick}
    data-cy="send-button-review-modal"
  >
    {isLoading ? <Spinner /> : 'Enviar'}
  </Button>
);

const Title = () => (
  <div className="flex flex-col items-center gap-y-5 w-full">
    <Icon face="edit" size="xl" variant="filled" className="text-primary text-[54px]" />
    <Text variant="h5" className="text-center">
      Gostaria de revisar o seu currículo?
    </Text>
  </div>
);

export const ModalReviewCurriculum = ({ show, onReview, onClose, onSend, isLoading = false }) => (
  <Modal
    size="sm"
    show={show}
    onClose={onClose}
    title={<Title />}
    showCloseIcon={false}
    cancelButton={<ReviewButton onClick={onReview} isLoading={isLoading} />}
    confirmButton={<SendButton onClick={onSend} isLoading={isLoading} />}
  >
    <Text variant="body1" className="text-center text-gray-800 mb-5">
      Ao enviar seu currículo, sua candidatura será realizada imediatamente.
    </Text>
  </Modal>
);
