import { IconProps } from './type';

export const IconJobs = ({ className = '' }: IconProps) => (
  <svg className={className} viewBox="0 0 496.78 219.44">
    <g>
      <path
        fill="#80287e"
        d="m173.15,218.22c-1.09,0-2.2-.07-3.34-.21-1.14-.14-2.06-.33-2.74-.56v-9c1.03.23,2.29.34,3.77.34,1.71,0,2.98-.36,3.81-1.07.83-.71,1.24-1.87,1.24-3.47v-49.01h11.74v49.69c0,4.23-1.26,7.5-3.77,9.81-2.51,2.31-6.08,3.47-10.71,3.47Zm8.57-68.29c-1.83,0-3.4-.66-4.71-1.97-1.31-1.31-1.97-2.88-1.97-4.71s.66-3.47,1.97-4.75c1.31-1.29,2.88-1.93,4.71-1.93s3.47.64,4.75,1.93,1.93,2.87,1.93,4.75-.64,3.4-1.93,4.71c-1.29,1.32-2.87,1.97-4.75,1.97Z"
      />
      <path
        fill="#80287e"
        d="m218.56,200.74c-4.46,0-8.48-1.03-12.08-3.08s-6.46-4.83-8.57-8.31c-2.11-3.48-3.17-7.4-3.17-11.74s1.04-8.27,3.13-11.78c2.08-3.51,4.94-6.3,8.57-8.35,3.63-2.06,7.67-3.08,12.12-3.08s8.5,1.03,12.12,3.08c3.63,2.06,6.48,4.84,8.57,8.35,2.08,3.51,3.13,7.44,3.13,11.78s-1.04,8.25-3.13,11.74c-2.09,3.48-4.93,6.25-8.52,8.31-3.6,2.06-7.65,3.08-12.17,3.08Zm0-10.2c2.34,0,4.44-.57,6.3-1.71,1.86-1.14,3.34-2.7,4.46-4.67s1.67-4.16,1.67-6.55-.56-4.65-1.67-6.6c-1.11-1.94-2.6-3.5-4.46-4.67-1.86-1.17-3.96-1.76-6.3-1.76s-4.37.59-6.25,1.76c-1.88,1.17-3.37,2.73-4.46,4.67-1.09,1.94-1.63,4.14-1.63,6.6s.54,4.58,1.63,6.55c1.08,1.97,2.57,3.53,4.46,4.67,1.88,1.14,3.97,1.71,6.25,1.71Z"
      />
      <path
        fill="#80287e"
        d="m249.57,199.89v-59.97l11.74-1.97v20.91c3.54-2.8,7.71-4.2,12.51-4.2,4.23,0,8.05,1.03,11.48,3.08s6.15,4.81,8.18,8.27c2.03,3.46,3.04,7.33,3.04,11.61s-1.03,8.15-3.08,11.61c-2.06,3.46-4.83,6.2-8.31,8.23-3.48,2.03-7.37,3.04-11.65,3.04-2.23,0-4.4-.31-6.51-.94-2.11-.63-4.06-1.54-5.83-2.74v3.08h-11.57Zm22.36-9.34c2.46,0,4.67-.57,6.64-1.71,1.97-1.14,3.53-2.68,4.67-4.63,1.14-1.94,1.71-4.14,1.71-6.6s-.57-4.67-1.71-6.64c-1.14-1.97-2.7-3.51-4.67-4.63-1.97-1.11-4.19-1.67-6.64-1.67-2.17,0-4.15.36-5.95,1.07-1.8.71-3.36,1.79-4.67,3.21v17.39c1.26,1.31,2.81,2.34,4.67,3.08,1.86.74,3.84,1.11,5.95,1.11Z"
      />
      <path
        fill="#80287e"
        d="m320.34,200.74c-4.11,0-7.91-.53-11.39-1.58-3.48-1.06-6.51-2.58-9.08-4.58l5.83-7.8c2.57,1.71,5.04,3,7.41,3.86,2.37.86,4.73,1.29,7.07,1.29,2.63,0,4.73-.44,6.3-1.33,1.57-.88,2.36-2.04,2.36-3.47,0-1.14-.44-2.06-1.33-2.74-.89-.69-2.3-1.17-4.24-1.46l-8.57-1.29c-4.46-.69-7.83-2.13-10.11-4.33-2.29-2.2-3.43-5.1-3.43-8.7,0-2.91.76-5.41,2.27-7.5,1.51-2.08,3.65-3.71,6.43-4.88,2.77-1.17,6.07-1.76,9.9-1.76,3.26,0,6.44.46,9.55,1.37,3.11.91,6.04,2.31,8.78,4.2l-5.66,7.62c-2.46-1.54-4.8-2.66-7.03-3.34-2.23-.69-4.48-1.03-6.77-1.03-2.11,0-3.81.4-5.1,1.2-1.29.8-1.93,1.86-1.93,3.17,0,1.2.46,2.14,1.37,2.83.91.69,2.49,1.17,4.71,1.46l8.48,1.29c4.46.63,7.85,2.04,10.2,4.24,2.34,2.2,3.51,5.04,3.51,8.52,0,2.86-.86,5.38-2.57,7.58-1.71,2.2-4.03,3.94-6.94,5.23s-6.25,1.93-10.02,1.93Z"
      />
    </g>
    <g>
      <path
        fill="#3d194f"
        d="m69.2,84.94c-1.02-2.28-2.52-4.29-4.37-5.9-1.46-1.3-3.15-2.33-5-3.08-6.13-2.45-14.72-4.2-21.86-5.25-6.25-.9-10.66-1.88-13.24-2.94-2.56-1.07-3.85-3.11-3.85-6.14,0-5.94,4.92-9.33,14.74-8.92,9.71.41,17.13,3.44,23.64,8.52,1.12,1.03,2.57,1.68,3.69,0l7.52-9.85c1.12-1.47,1.01-2.7-.34-3.71-8.6-7.97-19.94-11.95-34-11.95-9.73,0-17.87,2.31-24.47,6.89-6.59,4.6-9.88,11.28-9.88,20.02,0,3.93.67,7.4,2.01,10.43,0,.02.02.04.03.08,1.02,2.28,2.52,4.28,4.36,5.9,1.47,1.3,3.16,2.33,5,3.07,6.13,2.45,14.72,4.21,21.87,5.26,6.25.89,10.65,1.87,13.24,2.94,2.56,1.07,3.85,3.11,3.85,6.15,0,5.94-4.92,9.33-14.74,8.91-9.71-.41-17.13-3.44-23.64-8.51-1.12-1.03-2.58-1.68-3.69,0l-7.52,9.84c-1.12,1.48-1.01,2.7.34,3.71,8.6,7.97,19.94,11.95,34,11.95,9.73,0,17.87-2.31,24.47-6.9,6.59-4.59,9.88-11.28,9.88-20.02,0-3.93-.67-7.4-2.01-10.44,0-.02-.02-.04-.03-.07Z"
      />
      <path
        fill="#3d194f"
        d="m493.71,84.94c-1.02-2.28-2.52-4.29-4.37-5.9-1.46-1.3-3.15-2.33-5-3.08-6.13-2.45-14.72-4.2-21.86-5.25-6.25-.9-10.66-1.88-13.24-2.94-2.56-1.07-3.85-3.11-3.85-6.14,0-5.94,4.92-9.33,14.74-8.92,9.71.41,17.13,3.44,23.64,8.52,1.12,1.03,2.57,1.68,3.69,0l7.52-9.85c1.12-1.47,1.01-2.7-.34-3.71-8.6-7.97-19.94-11.95-34-11.95-9.73,0-17.87,2.31-24.47,6.89-6.59,4.6-9.88,11.28-9.88,20.02,0,3.93.67,7.4,2.01,10.43,0,.02.02.04.03.08,1.02,2.28,2.52,4.28,4.36,5.9,1.47,1.3,3.16,2.33,5,3.07,6.13,2.45,14.72,4.21,21.87,5.26,6.25.89,10.65,1.87,13.24,2.94,2.56,1.07,3.85,3.11,3.85,6.15,0,5.94-4.92,9.33-14.74,8.91-9.71-.41-17.13-3.44-23.64-8.51-1.12-1.03-2.58-1.68-3.69,0l-7.52,9.84c-1.12,1.48-1.01,2.7.34,3.71,8.6,7.97,19.94,11.95,34,11.95,9.73,0,17.87-2.31,24.47-6.9,6.59-4.59,9.88-11.28,9.88-20.02,0-3.93-.67-7.4-2.01-10.44,0-.02-.02-.04-.03-.07Z"
      />
      <path
        fill="#3d194f"
        d="m190.67.95h-13.12c-1.53,0-2.29.74-2.29,2.23v117.06c0,1.49.76,2.23,2.29,2.23h13.12c1.53,0,2.3-.74,2.3-2.23V3.18c0-1.49-.77-2.23-2.3-2.23Z"
      />
      <path
        fill="#3d194f"
        d="m319.76.95h-13.12c-1.53,0-2.3.75-2.3,2.23v40.93c-7.33-5.45-15.9-8.18-25.69-8.18-12.19,0-22.45,4.22-30.76,12.65-8.32,8.44-12.48,18.62-12.48,30.54s4.16,22.41,12.48,30.79c8.31,8.38,18.57,12.57,30.76,12.57,9.84,0,18.4-2.69,25.69-8.05,1.83-1.34,3.57-2.85,5.23-4.52,8.32-8.38,12.48-18.64,12.48-30.79V3.18c0-1.48-.77-2.23-2.3-2.23Zm-23.59,97.06c-4.62,4.95-10.45,7.42-17.51,7.42s-12.87-2.48-17.43-7.42c-4.56-4.95-6.84-11.25-6.84-18.89s2.28-13.8,6.84-18.81c4.56-5.01,10.36-7.51,17.43-7.51s12.89,2.53,17.51,7.6c4.61,5.06,6.92,11.3,6.92,18.72s-2.31,13.95-6.92,18.89Z"
      />
      <path
        fill="#3d194f"
        d="m220.74,36.03h-13.12c-1.53,0-2.29.74-2.29,2.23v81.99c0,1.49.76,2.23,2.29,2.23h13.12c1.53,0,2.3-.74,2.3-2.23V38.26c0-1.48-.77-2.23-2.3-2.23Z"
      />
      <path
        fill="#3d194f"
        d="m405.97,47.2c-8.1-8.44-18.21-12.66-30.33-12.66s-23.01,4.31-31.59,12.91c-8.59,8.61-12.87,19.07-12.87,31.38s4.32,22.63,12.96,30.95c8.64,8.32,19.27,12.48,31.87,12.48,14.52,0,26.82-4.79,36.9-14.37,1.08-1.25,1.08-2.39,0-3.42l-8.82-9.23c-1.08-1.26-2.28-1.26-3.6,0-6.24,6.73-14.22,10.09-23.94,10.09-6.84,0-12.63-2.14-17.38-6.41-4.74-4.28-7.47-9.83-8.19-16.67h64.27c1.68,0,2.52-.8,2.52-2.4.24-13.33-3.69-24.22-11.79-32.66Zm-54.28,23.6c1.44-5.82,4.3-10.49,8.55-14.03,4.26-3.53,9.39-5.3,15.39-5.3,5.64,0,10.53,1.8,14.67,5.39,4.14,3.59,6.75,8.24,7.83,13.94h-46.45Z"
      />
      <path
        fill="#3d194f"
        d="m123.14,122.27c-12.19,0-22.44-4.19-30.75-12.57-8.32-8.38-12.47-18.64-12.47-30.79s4.16-22.1,12.47-30.54c8.31-8.44,18.56-12.65,30.75-12.65s22.47,4.22,30.84,12.65c8.37,8.44,12.56,18.61,12.56,30.54s-4.16,22.41-12.47,30.79c-8.32,8.38-18.62,12.57-30.92,12.57Zm-17.43-24.46c4.55,4.95,10.36,7.42,17.43,7.42s12.9-2.47,17.51-7.42c4.61-4.95,6.92-11.25,6.92-18.89s-2.31-13.67-6.92-18.73c-4.61-5.06-10.45-7.59-17.51-7.59s-12.87,2.5-17.43,7.51c-4.56,5.01-6.84,11.28-6.84,18.81s2.28,13.95,6.84,18.89Z"
      />
      <path
        fill="#3d194f"
        d="m153.54.95h0c7.17,0,13,5.82,13,13v.12c0,7.17-5.82,13-13,13h-13v-13.11c0-7.17,5.82-13,13-13Z"
      />
      <rect fill="#3d194f" x="201.19" y=".95" width="25.99" height="26.11" rx="13" ry="13" />
    </g>
  </svg>
);
