/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
import { Icon, Text } from '@solides/management-util-ui';
import { useMemo } from 'react';

interface BulletProps {
  name: string;
  className?: string;
  colorCompany?: string;
  onRemove?: () => void;
  disabled?: boolean;
  disableRemove?: boolean;
}

export const Bullet = ({
  name,
  className = '',
  onRemove,
  disabled = false,
  disableRemove = false,
  colorCompany,
}: BulletProps) => {
  const variant = useMemo(() => {
    if (disabled) {
      return 'bg-gray-500/25 text-gray-500/80';
    }

    return `${`bg-[${colorCompany}] bg-opacity-25` || 'bg-primary-100'} ${
      `text-[${colorCompany}]` || 'text-primary'
    }`;
  }, [disabled, colorCompany]);

  return (
    <span
      role="option"
      className={`flex items-center gap-x-2 px-4 py-1 rounded-full ${variant} ${className}`}
    >
      <Text variant="caption">{name}</Text>

      {!(disableRemove || disabled) && (
        <button
          type="button"
          onClick={onRemove}
          className="flex bg-transparent text-primary-300 hover:text-primary"
        >
          <Icon face="cancel" size="sm" />
        </button>
      )}
    </span>
  );
};
