import { Box } from '@solides/management-util-ui';
import { HeaderBanner } from 'components/atoms';
import { Company } from 'types/company';

type FormRecommendType = {
  company: Company;
  children: JSX.Element | JSX.Element[];
  isExpired: boolean;
};

const RecommendationHeader = ({ company, children, isExpired }: FormRecommendType) => {
  return (
    <div data-testid="home-root">
      <header className="relative">
        <HeaderBanner
          banners={{ sm: '', md: '', lg: '' }}
          defaultColor={company?.colorCompany}
          className="flex relative z-0 h-[10px] sm:h-[10px]"
          isFromRecommendation
        />
        {isExpired && (
          <section className="w-full xl:px-0 px-4 max-w-6xl mx-auto relative bottom-0 z-10 m-5">
            <Box className="w-full p-6">
              <div>
                <h3 className="font-bold text-[18px] mb-2 text-[#212B36]">
                  A data para avaliar essa indicação expirou
                </h3>
                <p className="text-[16px] text-[#637381]">
                  O prazo para a confirmação dessa indicação expirou, isso significa que nesse momento o
                  recrutador visualiza ela como recusada. É possível modificar status para aprovada e é muito
                  simples, preencha as informações abaixo e clique em “Confirmar recomendação”.
                </p>
              </div>
            </Box>
          </section>
        )}
        <section className="w-full xl:px-0 px-4 max-w-6xl mx-auto relative bottom-0 z-10 m-5">
          <Box className="w-full p-6">{children}</Box>
        </section>
      </header>
    </div>
  );
};

export { RecommendationHeader };
