import { useRouter } from 'next/router';
import { useMemo, useState, useEffect } from 'react';
import { Checkbox, Icon, Text, TextField } from '@solides/management-util-ui';

import { Bullet } from 'components/atoms';
import { removeEmojisInString } from 'utils/format';
import { SelectedLocations } from './components/SelectedLocations';

interface FieldLocationProps {
  onChange: (value: string) => void;
  locations: string[];
  locationsSelected: string[];
  clearLocations: () => void;
  setLocationsSelected: (location: string) => void;
}

export const FieldLocation = ({
  onChange,
  locations,
  clearLocations,
  locationsSelected,
  setLocationsSelected,
}: FieldLocationProps) => {
  const router = useRouter();
  const [textLocation, setTextLocation] = useState('');
  const [active, setActive] = useState(false);

  const contentHeight = useMemo(() => (active ? ' max-h-[700px] z-10' : ' max-h-12'), [active]);

  useEffect(() => {
    onChange(removeEmojisInString(textLocation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textLocation]);

  useEffect(() => {
    if (active === false) {
      setTextLocation('');
      onChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    setActive(false);
  }, [router.asPath]);

  const onFocusTab = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <div>
      <Text variant="body2" className="mb-1">
        Local
      </Text>

      <span className="hidden" id="locals-field">
        {locationsSelected.join(',')}
      </span>

      <div className="w-full h-12 relative">
        <div
          data-cy="local-field-content"
          data-testid="local-field-content"
          className={`absolute top-0 left-0 w-full bg-white border rounded-lg border-gray-500/[.32] hover:border-gray-800 overflow-hidden transition-all duration-500 ${contentHeight}`}
        >
          <button
            type="button"
            data-cy="local-field-button"
            data-testid="local-field-button"
            onClick={() => setActive(state => !state)}
            className="px-5 w-full h-12 flex items-center gap-x-1"
          >
            <Icon face="location_on" variant="outlined" className="text-gray-500" />

            {locationsSelected.length ? (
              <Bullet name={locationsSelected[0]} disableRemove />
            ) : (
              <Text variant="body2" className="text-gray-500">
                Estado ou cidade
              </Text>
            )}

            {locationsSelected.length > 1 && (
              <Bullet name={`+ ${locationsSelected.length - 1}`} className="px-2" disableRemove />
            )}

            <Icon
              face="keyboard_arrow_down"
              className={'ml-auto transition duration-300 '.concat(active ? '-rotate-180' : '')}
            />
          </button>

          <div className="flex flex-col gap-y-3 px-4 pb-4 mt-1">
            <SelectedLocations
              locations={locationsSelected}
              removeLocation={setLocationsSelected}
              clearLocations={clearLocations}
              onFocusTab={onFocusTab}
            />

            <div className="field-location-container-input">
              <TextField
                label=""
                type="text"
                fieldSize="md"
                value={textLocation}
                maxLength={50}
                data-cy="local-field-input"
                data-testid="local-field-input"
                iconLeft={{ icon: 'search' }}
                onChange={e => setTextLocation(e.target.value)}
                placeholder="Digite o nome cidade"
                onFocus={onFocusTab}
              />
            </div>

            <ul className="flex flex-col">
              {locations.map(location => (
                <li key={location}>
                  <Checkbox
                    id={location}
                    label={location}
                    checked={locationsSelected.includes(location)}
                    onClick={() => setLocationsSelected(location)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
