import { getPublisherInfo } from './getPublisherInfo';
import { Company } from 'types/company';

export const getCompanyData = async context => {
  let company;

  try {
    company = await getPublisherInfo(context);
  } catch (err) {
    console.log(err);
  }

  return company as Company;
};
