export function thumbnail(url, fixed = true, type = 'youtube') {
  if (url === undefined || url === null) return '';

  let link = '';

  if (type === 'youtube') {
    const code = url
      .replace('https://www.youtube.com/watch?v=', '')
      .replace('https://www.youtube.com/embed/', '')
      .replace('https://youtu.be/', '');

    if (code)
      link = fixed ? `https://img.youtube.com/vi/${code}/0.jpg` : `https://www.youtube.com/embed/${code}`;
  }

  return link;
}
