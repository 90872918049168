import Image from 'next/image';
import { GallerySkeleton } from './Skeleton';
import { twMerge } from 'tailwind-merge';

const MAX_WIDTH = 2685;
const MAX_HEIGHT = 800;

export const GallerySection = ({ data, isLoading, className = '' }) => {
  const renderAnotherPictures = data?.slice(1, data.length).map((item: string, index: number) => {
    return (
      <Image
        src={item}
        key={index}
        alt="Company photo"
        width={MAX_WIDTH / (data.length - 1)}
        height={MAX_HEIGHT / (data.length - 1)}
        objectFit="cover"
        layout="responsive"
      />
    );
  });

  if (isLoading) return <GallerySkeleton />;

  if (!data.length) return null;

  const firstPhotoClass = twMerge('flex-1 h-fit', data.length > 1 && 'md:w-3/5');

  return (
    <section className={className} data-testid="gallery-photos">
      <div className={firstPhotoClass}>
        <Image
          src={data[0]}
          alt="Company photo"
          layout="responsive"
          objectFit="cover"
          width={MAX_WIDTH}
          height={MAX_HEIGHT}
        />
      </div>

      {data.length > 1 && <div className="w-full md:w-2/5 flex flex-col gap-3">{renderAnotherPictures}</div>}
    </section>
  );
};
