import Head from 'next/head';
import { useRouter } from 'next/router';

interface SEOProps {
  tabName: string;
  title: string;
  description: string;
  keywords?: string;
  image?: string;
  baseUrl?: string;
  googleForJobsData?: string;
  canonical?: string;
  noIndex?: boolean;
}

export const SEO = ({
  tabName,
  title,
  description,
  keywords = '',
  image,
  baseUrl = '',
  googleForJobsData = '',
  noIndex = false,
  canonical,
}: SEOProps): JSX.Element => {
  const { asPath } = useRouter();

  return (
    <Head>
      <title>{tabName}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={baseUrl.concat(asPath)} />

      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {canonical && <link rel="canonical" href={canonical} />}

      {image && (
        <>
          <meta property="og:image" itemProp="image" content={image} />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />

          <meta name="twitter:image" content={image} />
          <meta name="twitter:image:alt" content={title} />
          <meta name="twitter:card" content="summary_large_image" />
        </>
      )}

      {noIndex && <meta name="robots" content="noindex" />}

      {googleForJobsData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(googleForJobsData),
          }}
        />
      )}
    </Head>
  );
};
