import { Button, Icon, Modal, Text } from '@solides/management-util-ui';

const ReviewButton = ({ onClick }) => (
  <Button
    size="md"
    className="flex-1 justify-center text-button-sm"
    variant="outlined"
    onClick={onClick}
    data-cy="review-button"
  >
    Cancelar
  </Button>
);

const SendButton = ({ onClick }) => (
  <Button
    size="md"
    color="primary"
    className="flex-1 justify-center text-button-sm"
    variant="contained"
    onClick={onClick}
    data-cy="send-button-review-modal"
  >
    Central de ajuda
  </Button>
);

const Title = ({ title }) => (
  <div className="flex flex-col items-center gap-y-5 w-full">
    <Icon face="warning" size="xl" variant="filled" className="text-primary text-[54px]" />
    <Text variant="h5" className="text-center">
      {title}
    </Text>
  </div>
);

const URL_CENTRAL_AJUDA = 'https://ajuda.solides.com.br/hc/pt-br/categories/4411290914189-Sou-candidato-a-';
export const ModalDuplicatedAcount = ({ show, onClose }) => (
  <Modal
    size="sm"
    show={show}
    onClose={onClose}
    title={<Title title="Não é possível se candidatar a vagas dessa empresa" />}
    showCloseIcon={false}
    cancelButton={<ReviewButton onClick={onClose} />}
    confirmButton={<SendButton onClick={() => window.open(URL_CENTRAL_AJUDA, '_blank')?.focus()} />}
  >
    <Text variant="body1" className="text-center text-gray-800 mb-6">
      O CPF fornecido já se candidatou a uma oportunidade com um outro e-mail para essa empresa e por isso não
      é possível continuar com a sua candidatura.
      <br />
      Clique em cancelar e realize o log-in com a sua conta principal ou clique em Central de ajuda para
      entrar em contato com o nosso suporte.
    </Text>
  </Modal>
);
