import { CreateCompany } from './types';

export const createCompany: CreateCompany = set => ({
  company: null,
  setCompany: newStatus => {
    set(draft => {
      draft.company.company = newStatus;

      return draft;
    });
  },
  loading: false,
  setLoading: newStatus => {
    set(draft => {
      draft.company.loading = newStatus;

      return draft;
    });
  },
  statuses: {},
  setStatus: newStatus => {
    set(draft => {
      draft.company.statuses = newStatus;

      return draft;
    });
  },
});
