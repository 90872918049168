import { api } from 'services/api';

export const getRecommendationBlocks = async (email: string): Promise<string[]> => {
  try {
    const response = await api.get(`/recommendations-block?email=${email}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
  return [];
};
