import { Text } from '@solides/management-util-ui';
import { SVGSearchVacancies } from './svgs/SearchVacancies';

export const ListEmptyVacancies = () => (
  <div className="flex items-center gap-x-2 m-auto py-6">
    <div className="flex flex-col gap-y-4 md:gap-y-2 text-center md:text-start">
      <h1 className="leading-9 md:leading-xl text-lg md:text-xl text-primary max-w-[390px] font-bold">
        Ops! Vaga não encontrada
      </h1>

      <div className="block md:hidden mx-auto">
        <SVGSearchVacancies className="w-[268px] h-auto" />
      </div>

      <Text variant="h6" className="text-gray-600 max-w-[360px]">
        Mas não desanime, tente uma nova busca e veja o universo de vagas que nós temos para você.
      </Text>
    </div>

    <div className="hidden md:block">
      <SVGSearchVacancies />
    </div>
  </div>
);
