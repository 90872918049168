import Link from 'next/link';

import { IconNotFound } from 'components/atoms';

export const NotFound = () => (
  <section className="flex flex-col-reverse sm:flex-row items-center gap-x-10 justify-center pt-[5vh] sm:pt-[10vh] pb-10 px-4 my-auto">
    <div className="flex flex-col items-center text-center">
      <h1 className="font-black text-[120px] sm:text-[180px] lg:text-[200px] text-primary leading-none">
        404
      </h1>

      <p className="text-gray-600 max-w-[490px] text-[16px] sm:text-[20px] font-bold mt-4 sm:mt-2">
        Ops! Desculpe, essa página não foi encontrada!
      </p>

      <br />

      <p className="text-gray-600 max-w-[490px] text-[16px] sm:text-[20px] font-bold">
        Caso ela tenha sido criada recentemente, aguarde alguns instantes até que ela seja sincronizada.
      </p>

      <Link href="/" legacyBehavior>
        <a className="w-[270px] h-[48px] flex items-center text-white font-bold justify-center mt-6 sm:mt-8 bg-primary rounded-lg hover:shadow-none disabled:shadow-none transition duration-300 ease-in-out shadow-md-primary hover:text-white hover:bg-primary-700">
          Voltar para as vagas
        </a>
      </Link>
    </div>

    <div className="w-[220px] sm:w-[300px] lg:w-[414px] h-auto">
      <IconNotFound />
    </div>
  </section>
);
