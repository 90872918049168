import { CandidacyContext } from 'contexts';
import { useContext } from 'react';

function useCandidacy() {
  const hook = useContext(CandidacyContext);

  if (!hook) throw new Error('useCandidacy should be used within a CandidacyProvider');

  return hook;
}

export { useCandidacy };
