import {
  init,
  fetchFeatures,
  featuresToggle,
  setFeaturesCache,
} from '@solides-front/jobs-frontend-utils/firebase';

init({
  appId: process.env.FIREBASE_APP_ID,
  apiKey: process.env.FIREBASE_API_KEY,
  projectId: process.env.FIREBASE_PROJECT_ID,
});

export { fetchFeatures, featuresToggle, setFeaturesCache };
