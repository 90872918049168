import { create, GetState } from 'zustand';
import { devtools, NamedSet } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { Draft } from 'immer';
import { Store } from './types';
import { createSession } from './session';
import { createFeaturesToggle } from './featuresToggle';
import { createCompany } from './company';

// Definindo os tipos dos mutadores
type StoreMutators = {
  featuresToggle: NamedSet<Store>;
  session: NamedSet<Store>;
  company: NamedSet<Store>;
};

// Criando a store com os tipos definidos
const useStore = create(
  devtools(
    immer((set: NamedSet<Store>, get: GetState<Store>) => ({
      featuresToggle: createFeaturesToggle(set, get),
      session: createSession(set, get),
      company: createCompany(set, get),
    }))
  )
);

export default useStore;

// Exportando tipos para uso nos testes
export type StateDraft<T> = (partial: Partial<T> | ((draft: Draft<T>) => void), replace?: boolean) => void;
