import { ButtonHTMLAttributes } from 'react';

interface MenuToggleProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const MenuToggle = ({ className = '', ...props }: MenuToggleProps) => (
  <button
    type="button"
    data-cy="menu-toggle"
    data-testid="menu-toggle"
    className={`menu-toggle ${className || ''}`}
    aria-label="Botão para exibir ou ocultar o menu mobile"
    {...props}
  >
    <span />
    <span />
    <span />
  </button>
);
