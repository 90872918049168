interface IRequest {
  headers: { host: string };
}

export interface IPlatformPrefix {
  ctx?: { req: IRequest };
  req?: IRequest;
  query: object;
}

export function getPlatformPrefix(context: IPlatformPrefix) {
  const req = context?.ctx?.req || context.req;
  const prefix = (req ? req.headers.host : window.location.hostname)
    .replace(process.env.JOBS_HOST, '')
    .split('.')[0];

  return prefix;
}
