import React from 'react';
import { Button, Icon, Modal, Text } from '@solides/management-util-ui';

const Title = () => (
  <div className="flex flex-col items-center gap-y-5 w-full">
    <Icon face="edit" size="xl" variant="filled" className="text-primary text-[54px]" />
    <Text variant="h5" className="text-center">
      Gostaria de atualizar o seu currículo?
    </Text>
  </div>
);

export const ModalTalentBankApplied = ({ show, onSend, onRefresh, onClose }) => (
  <Modal
    size="sm"
    show={show}
    title={<Title />}
    onClose={onClose}
    showCloseIcon={false}
    cancelButton={
      <Button
        size="lg"
        className="flex-1 justify-center mb-3"
        variant="outlined"
        onClick={onRefresh}
        data-cy="modal-curriculum-update"
      >
        Atualizar
      </Button>
    }
    confirmButton={
      <Button
        size="lg"
        color="primary"
        className="flex-1 justify-center mb-3"
        variant="contained"
        onClick={onSend}
        data-cy="modal-curriculum-send"
      >
        Enviar
      </Button>
    }
  >
    <Text variant="body1" className="text-center text-gray-600 mb-5">
      Ao enviar o seu currículo, seu cadastro será realizado imediatamente.
    </Text>
  </Modal>
);
