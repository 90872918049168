import Cookies from 'js-cookie';
import { generateUuid } from './generateUniqueId';

const cookies = Cookies.withAttributes({
  path: '/',
  expires: 2,
  secure: true,
  SameSite: 'None',
  domain: process.env.COOKIE_DOMAIN,
});

export const init = () => {
  // Adicioando essa validação por conta do iphone trocar a key da aba
  if (!window.name) window.name = cookies.get('redirectCurrentWindow') || generateUuid();
};

export const redirect = (url: string) => {
  // Adicioando essa validação por conta do iphone trocar a key da aba
  cookies.set('redirectCurrentWindow', window.name, { expires: null });
  window.location.href = url;
};

export default cookies;
export { cookies };
