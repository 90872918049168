import { EMOJIS_CODE, TOTAL_VACANCIES, JOBS_URLS } from './constants';
import { QueryVacancy, GetParamsSearchVacancy } from 'types/vacancy';

export const truncateString = (value: string, maxLen = 200): string => {
  if (value.length > maxLen) {
    return value.substring(0, maxLen) + '...';
  }

  return value;
};

export const numberToCurrency = (value: number, decimals = 0): string => {
  const optionsCurrency: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  };

  return new Intl.NumberFormat('pt-BR', optionsCurrency).format(value);
};

export const removeEmojisInString = (value: string): string =>
  value.replace(EMOJIS_CODE, '').replace(/  +/g, ' ').trimStart();

export const getParamsSearchVacancy = ({
  title = '',
  locations = '',
  ...query
}: QueryVacancy): GetParamsSearchVacancy => {
  const params = {
    ...query,
    title,
    locations,
    take: TOTAL_VACANCIES,
    page: query?.page ? Number(query.page) : 1,
  };

  if (query.salary) {
    const typeSalary = query.salary === '999.99' ? 'maxSalary' : 'minSalary';
    params[typeSalary] = Number(query.salary);
  }

  return params;
};

export const getIdVideoYoutube = (value: string): string => {
  if (!value) return '';

  const isValidUrl = value.includes('v=');

  if (isValidUrl) {
    return value.split('v=')[1];
  }

  return '';
};

export const removingTagsHtml = (value: string): string => {
  return value.replace(/<\/?[^>]+(>|$)/g, '');
};

export const removingMarginsHtml = (value: string): string => {
  return value?.replace(/-42.55pt/g, '0cm');
};

export const formatTextRich = (value: string): string[] => {
  if (!value) {
    return [];
  }

  const values = removingTagsHtml(value).split('\n');

  return values.filter(t => t !== '' && t !== '\r');
};

export const removingRightIdent = (value: string): string => {
  if (!value) return;
  return value.replace(/(padding-left: 1120px;)/g, 'text-align: right;');
};

export const formatUrlToJOBS = (value: string): string => {
  if (!value) {
    return '/';
  }

  const host = JOBS_URLS[process.env.ENV || 'production'];
  return `https://${value}${host}`;
};

export const getSlugFromText = (value: string): string => {
  if (!value) {
    return '';
  }

  let newValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  newValue = new URLSearchParams(newValue).toString();
  newValue = newValue.replace(/\+/g, '-').replace(/[^\w-]+/g, '');

  return newValue.toLocaleLowerCase();
};

export const removeEmpty = (obj: object) => {
  if (!obj.toString()) {
    return obj;
  }

  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => value !== null && value !== undefined && value !== '')
      .map(([key, value]) => [key, value === Object(value) ? removeEmpty(value) : value])
  );
};
