export const SVGSearchVacancies = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="429"
    height="431"
    fill="none"
    viewBox="0 0 429 431"
    className={className}
  >
    <g clipPath="url(#clip0_1006_375870)">
      <rect
        width="176.537"
        height="396.065"
        x="249.06"
        y="59.342"
        fill="#00ACC1"
        rx="88.269"
        transform="rotate(45 249.06 59.342)"
      />
      <rect
        width="176.537"
        height="396.065"
        x="339.399"
        y="41"
        fill="#583F99"
        rx="88.269"
        transform="rotate(45 339.399 41)"
      />
      <g style={{ mixBlendMode: 'multiply' }} fill="#583F99" opacity="0.5">
        <circle cx="315.227" cy="81.333" r="1.915" transform="rotate(90 315.227 81.333)" />
        <circle cx="293.889" cy="81.333" r="1.915" transform="rotate(90 293.889 81.333)" />
        <circle cx="315.227" cy="166.693" r="1.915" transform="rotate(90 315.227 166.693)" />
        <circle cx="293.889" cy="166.693" r="1.915" transform="rotate(90 293.889 166.693)" />
        <circle cx="315.227" cy="124.013" r="1.915" transform="rotate(90 315.227 124.013)" />
        <circle cx="293.889" cy="124.013" r="1.915" transform="rotate(90 293.889 124.013)" />
        <circle cx="315.227" cy="209.372" r="1.915" transform="rotate(90 315.227 209.372)" />
        <circle cx="293.889" cy="209.372" r="1.915" transform="rotate(90 293.889 209.372)" />
        <circle cx="315.227" cy="102.674" r="1.915" transform="rotate(90 315.227 102.674)" />
        <circle cx="293.889" cy="102.674" r="1.915" transform="rotate(90 293.889 102.674)" />
        <circle cx="315.227" cy="188.032" r="1.915" transform="rotate(90 315.227 188.032)" />
        <circle cx="293.889" cy="188.032" r="1.915" transform="rotate(90 293.889 188.032)" />
        <circle cx="315.227" cy="145.352" r="1.915" transform="rotate(90 315.227 145.352)" />
        <circle cx="293.889" cy="145.352" r="1.915" transform="rotate(90 293.889 145.352)" />
        <circle cx="315.227" cy="230.71" r="1.915" transform="rotate(90 315.227 230.71)" />
        <circle cx="293.889" cy="230.71" r="1.915" transform="rotate(90 293.889 230.71)" />
        <circle cx="272.549" cy="81.334" r="1.915" transform="rotate(90 272.549 81.334)" />
        <circle cx="251.209" cy="81.334" r="1.915" transform="rotate(90 251.209 81.334)" />
        <circle cx="272.549" cy="166.693" r="1.915" transform="rotate(90 272.549 166.693)" />
        <circle cx="251.209" cy="166.693" r="1.915" transform="rotate(90 251.209 166.693)" />
        <circle cx="272.549" cy="124.013" r="1.915" transform="rotate(90 272.549 124.013)" />
        <circle cx="251.209" cy="124.013" r="1.915" transform="rotate(90 251.209 124.013)" />
        <circle cx="272.549" cy="209.372" r="1.915" transform="rotate(90 272.549 209.372)" />
        <circle cx="251.209" cy="209.373" r="1.915" transform="rotate(90 251.209 209.373)" />
        <circle cx="272.549" cy="102.674" r="1.915" transform="rotate(90 272.549 102.674)" />
        <circle cx="251.209" cy="102.674" r="1.915" transform="rotate(90 251.209 102.674)" />
        <circle cx="272.549" cy="188.032" r="1.915" transform="rotate(90 272.549 188.032)" />
        <circle cx="251.209" cy="188.032" r="1.915" transform="rotate(90 251.209 188.032)" />
        <circle cx="272.549" cy="145.352" r="1.915" transform="rotate(90 272.549 145.352)" />
        <circle cx="251.209" cy="145.352" r="1.915" transform="rotate(90 251.209 145.352)" />
        <circle cx="272.549" cy="230.71" r="1.915" transform="rotate(90 272.549 230.71)" />
        <circle cx="251.209" cy="230.71" r="1.915" transform="rotate(90 251.209 230.71)" />
        <circle cx="229.864" cy="81.333" r="1.915" transform="rotate(90 229.864 81.333)" />
        <circle cx="208.522" cy="81.333" r="1.915" transform="rotate(90 208.522 81.333)" />
        <circle cx="229.864" cy="166.693" r="1.915" transform="rotate(90 229.864 166.693)" />
        <circle cx="208.522" cy="166.693" r="1.915" transform="rotate(90 208.522 166.693)" />
        <circle cx="229.864" cy="124.013" r="1.915" transform="rotate(90 229.864 124.013)" />
        <circle cx="208.522" cy="124.013" r="1.915" transform="rotate(90 208.522 124.013)" />
        <circle cx="229.864" cy="209.372" r="1.915" transform="rotate(90 229.864 209.372)" />
        <circle cx="208.522" cy="209.372" r="1.915" transform="rotate(90 208.522 209.372)" />
        <circle cx="229.864" cy="102.674" r="1.915" transform="rotate(90 229.864 102.674)" />
        <circle cx="208.522" cy="102.674" r="1.915" transform="rotate(90 208.522 102.674)" />
        <circle cx="229.864" cy="188.032" r="1.915" transform="rotate(90 229.864 188.032)" />
        <circle cx="208.522" cy="188.032" r="1.915" transform="rotate(90 208.522 188.032)" />
        <circle cx="229.864" cy="145.352" r="1.915" transform="rotate(90 229.864 145.352)" />
        <circle cx="208.522" cy="145.352" r="1.915" transform="rotate(90 208.522 145.352)" />
        <circle cx="229.864" cy="230.71" r="1.915" transform="rotate(90 229.864 230.71)" />
        <circle cx="208.522" cy="230.71" r="1.915" transform="rotate(90 208.522 230.71)" />
        <circle cx="187.186" cy="81.334" r="1.915" transform="rotate(90 187.186 81.334)" />
        <circle cx="165.847" cy="81.333" r="1.915" transform="rotate(90 165.847 81.333)" />
        <circle cx="187.186" cy="166.694" r="1.915" transform="rotate(90 187.186 166.694)" />
        <circle cx="165.847" cy="166.694" r="1.915" transform="rotate(90 165.847 166.694)" />
        <circle cx="187.186" cy="124.015" r="1.915" transform="rotate(90 187.186 124.015)" />
        <circle cx="165.847" cy="124.015" r="1.915" transform="rotate(90 165.847 124.015)" />
        <circle cx="187.186" cy="209.374" r="1.915" transform="rotate(90 187.186 209.374)" />
        <circle cx="165.847" cy="209.374" r="1.915" transform="rotate(90 165.847 209.374)" />
        <circle cx="187.186" cy="102.676" r="1.915" transform="rotate(90 187.186 102.676)" />
        <circle cx="165.847" cy="102.676" r="1.915" transform="rotate(90 165.847 102.676)" />
        <circle cx="187.186" cy="188.034" r="1.915" transform="rotate(90 187.186 188.034)" />
        <circle cx="165.847" cy="188.034" r="1.915" transform="rotate(90 165.847 188.034)" />
        <circle cx="187.186" cy="145.354" r="1.915" transform="rotate(90 187.186 145.354)" />
        <circle cx="165.847" cy="145.354" r="1.915" transform="rotate(90 165.847 145.354)" />
        <circle cx="187.186" cy="230.712" r="1.915" transform="rotate(90 187.186 230.712)" />
        <circle cx="165.847" cy="230.712" r="1.915" transform="rotate(90 165.847 230.712)" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} fill="#583F99" opacity="0.5">
        <circle cx="142.033" cy="82.167" r="1.915" transform="rotate(90 142.033 82.167)" />
        <circle cx="120.694" cy="82.167" r="1.915" transform="rotate(90 120.694 82.167)" />
        <circle cx="142.033" cy="167.525" r="1.915" transform="rotate(90 142.033 167.525)" />
        <circle cx="120.694" cy="167.525" r="1.915" transform="rotate(90 120.694 167.525)" />
        <circle cx="142.033" cy="124.845" r="1.915" transform="rotate(90 142.033 124.845)" />
        <circle cx="120.694" cy="124.845" r="1.915" transform="rotate(90 120.694 124.845)" />
        <circle cx="142.033" cy="210.203" r="1.915" transform="rotate(90 142.033 210.203)" />
        <circle cx="120.694" cy="210.203" r="1.915" transform="rotate(90 120.694 210.203)" />
        <circle cx="142.033" cy="103.506" r="1.915" transform="rotate(90 142.033 103.506)" />
        <circle cx="120.694" cy="103.506" r="1.915" transform="rotate(90 120.694 103.506)" />
        <circle cx="142.033" cy="188.865" r="1.915" transform="rotate(90 142.033 188.865)" />
        <circle cx="120.694" cy="188.865" r="1.915" transform="rotate(90 120.694 188.865)" />
        <circle cx="142.033" cy="146.185" r="1.915" transform="rotate(90 142.033 146.185)" />
        <circle cx="120.694" cy="146.185" r="1.915" transform="rotate(90 120.694 146.185)" />
        <circle cx="142.033" cy="231.544" r="1.915" transform="rotate(90 142.033 231.544)" />
        <circle cx="120.694" cy="231.544" r="1.915" transform="rotate(90 120.694 231.544)" />
        <circle cx="99.357" cy="82.167" r="1.915" transform="rotate(90 99.357 82.167)" />
        <circle cx="78.018" cy="82.167" r="1.915" transform="rotate(90 78.018 82.167)" />
        <circle cx="99.357" cy="167.525" r="1.915" transform="rotate(90 99.357 167.525)" />
        <circle cx="78.018" cy="167.525" r="1.915" transform="rotate(90 78.018 167.525)" />
        <circle cx="99.357" cy="124.845" r="1.915" transform="rotate(90 99.357 124.845)" />
        <circle cx="78.018" cy="124.845" r="1.915" transform="rotate(90 78.018 124.845)" />
        <circle cx="99.357" cy="210.203" r="1.915" transform="rotate(90 99.357 210.203)" />
        <circle cx="78.018" cy="210.203" r="1.915" transform="rotate(90 78.018 210.203)" />
        <circle cx="99.357" cy="103.506" r="1.915" transform="rotate(90 99.357 103.506)" />
        <circle cx="78.018" cy="103.506" r="1.915" transform="rotate(90 78.018 103.506)" />
        <circle cx="99.357" cy="188.865" r="1.915" transform="rotate(90 99.357 188.865)" />
        <circle cx="78.018" cy="188.865" r="1.915" transform="rotate(90 78.018 188.865)" />
        <circle cx="99.357" cy="146.185" r="1.915" transform="rotate(90 99.357 146.185)" />
        <circle cx="78.018" cy="146.185" r="1.915" transform="rotate(90 78.018 146.185)" />
        <circle cx="99.357" cy="231.542" r="1.915" transform="rotate(90 99.357 231.542)" />
        <circle cx="78.018" cy="231.542" r="1.915" transform="rotate(90 78.018 231.542)" />
        <circle cx="56.68" cy="82.167" r="1.915" transform="rotate(90 56.68 82.167)" />
        <circle cx="35.342" cy="82.167" r="1.915" transform="rotate(90 35.342 82.167)" />
        <circle cx="56.68" cy="167.525" r="1.915" transform="rotate(90 56.68 167.525)" />
        <circle cx="35.342" cy="167.525" r="1.915" transform="rotate(90 35.342 167.525)" />
        <circle cx="56.68" cy="124.845" r="1.915" transform="rotate(90 56.68 124.845)" />
        <circle cx="35.342" cy="124.845" r="1.915" transform="rotate(90 35.342 124.845)" />
        <circle cx="56.68" cy="210.203" r="1.915" transform="rotate(90 56.68 210.203)" />
        <circle cx="35.342" cy="210.203" r="1.915" transform="rotate(90 35.342 210.203)" />
        <circle cx="56.68" cy="103.506" r="1.915" transform="rotate(90 56.68 103.506)" />
        <circle cx="35.342" cy="103.506" r="1.915" transform="rotate(90 35.342 103.506)" />
        <circle cx="56.68" cy="188.865" r="1.915" transform="rotate(90 56.68 188.865)" />
        <circle cx="35.342" cy="188.865" r="1.915" transform="rotate(90 35.342 188.865)" />
        <circle cx="56.68" cy="146.185" r="1.915" transform="rotate(90 56.68 146.185)" />
        <circle cx="35.342" cy="146.185" r="1.915" transform="rotate(90 35.342 146.185)" />
        <circle cx="56.68" cy="231.544" r="1.915" transform="rotate(90 56.68 231.544)" />
        <circle cx="35.342" cy="231.544" r="1.915" transform="rotate(90 35.342 231.544)" />
        <circle cx="13.997" cy="82.166" r="1.915" transform="rotate(90 13.997 82.166)" />
        <circle cx="13.997" cy="167.526" r="1.915" transform="rotate(90 13.997 167.526)" />
        <circle cx="13.997" cy="124.845" r="1.915" transform="rotate(90 13.997 124.845)" />
        <circle cx="13.997" cy="210.203" r="1.915" transform="rotate(90 13.997 210.203)" />
        <circle cx="13.997" cy="103.506" r="1.915" transform="rotate(90 13.997 103.506)" />
        <circle cx="13.997" cy="188.865" r="1.915" transform="rotate(90 13.997 188.865)" />
        <circle cx="13.997" cy="146.185" r="1.915" transform="rotate(90 13.997 146.185)" />
        <circle cx="13.997" cy="231.544" r="1.915" transform="rotate(90 13.997 231.544)" />
      </g>
    </g>
    <g clipPath="url(#clip1_1006_375870)">
      <path
        fill="#311F6E"
        d="M202.123 413.961c62.148 0 112.528-6.737 112.528-15.047s-50.38-15.047-112.528-15.047-112.528 6.737-112.528 15.047 50.38 15.047 112.528 15.047z"
        opacity="0.3"
      />
      <g clipPath="url(#clip2_1006_375870)">
        <path
          fill="#fff"
          d="M194.888 283.321c.494 7.428 1.501 30.081 2.602 53.087 1.327 27.798 2.792 56.107 3.68 58.653 2.662 7.636 9.576 8.304 9.576 8.304h27.099s8.283-.012 11.927-4.413c5.984-7.232-.101-15.101-.101-15.101s.459-32.793.757-62.811c.124-12.9.22-25.294.239-34.346l-55.777-3.373h-.002z"
        />
        <path
          fill="#371A45"
          d="M194.888 283.321c.493 7.428 1.501 30.081 2.602 53.087 20.775 1.326 37.633-9.41 52.935-15.368.124-12.9.22-25.294.238-34.346l-55.777-3.373h.002zM200.247 386.693c.346 4.597.661 7.612.923 8.368 2.662 7.636 9.576 8.304 9.576 8.304h27.099s8.283-.012 11.927-4.413c2.989-3.609 2.966-7.381 2.192-10.247 0 .006-.005.011-.012.018-3.947 4.764-12.922 4.776-12.922 4.776h-29.362s-6.116-.588-9.419-6.804l-.002-.002z"
          opacity="0.29"
        />
        <path
          fill="#371A45"
          d="M210.746 405.98h27.099c.977 0 9.641-.161 13.944-5.361 5.373-6.495 2.929-13.916.514-17.582.109-7.906.933-68.396.982-96.336a2.621 2.621 0 00-2.617-2.624h-.005a2.618 2.618 0 00-2.621 2.614c-.052 29.744-.984 96.455-.994 97.125a2.62 2.62 0 00.547 1.638c.186.246 4.7 6.333.153 11.829-2.325 2.809-7.942 3.45-9.911 3.46H210.91c-.712-.109-5.341-1.023-7.264-6.543-1.004-3.569-3.063-47.61-4.428-76.752-.726-15.517-1.352-28.918-1.713-34.303a2.624 2.624 0 00-5.234.349c.358 5.332.982 18.708 1.707 34.197 2.37 50.639 3.679 75.26 4.713 78.229 3.176 9.118 11.45 10.015 11.801 10.048.084.009.168.012.25.012h.004z"
        />
        <path
          fill="#fff"
          d="M134.873 386.55c-3.167 3.92-3.59 9.588.744 13.573 4.34 3.984 20.674 3.17 20.674 3.17h23.634s6.913-.666 9.575-8.304c2.06-5.924.84-30.379 1.578-58.301.154-5.93.4-12.014.781-18.111l-59.426-28.916c.923 10.89 1.935 21.547 2.959 31.533 3.501 34.281 7.056 60.575 7.056 60.575s-4.859 1.415-7.575 4.781z"
        />
        <path
          fill="#371A45"
          d="M135.617 400.124c4.338 3.98 20.671 3.169 20.671 3.169h23.634s6.915-.668 9.575-8.3c.334-.956.584-2.396.767-4.262-3.028 2.344-6.096 2.639-6.096 2.639h-26.72s-18.463.917-23.372-3.587a9.648 9.648 0 01-.734-.74c-1.619 3.594-1.205 7.881 2.275 11.083v-.002zM132.431 289.661c.923 10.89 1.935 21.547 2.959 31.533 3.99 1.38 8.498 3.266 13.427 5.859 17.275 9.093 35.626 9.742 42.259 9.635.155-5.93.4-12.014.781-18.111l-59.426-28.916z"
          opacity="0.29"
        />
        <path
          fill="#371A45"
          d="M152.515 406c1.739 0 3.113-.052 3.84-.087h23.568c.084 0 .168-.004.252-.012.351-.033 8.625-.93 11.801-10.048 1.44-4.134 1.442-14.481 1.443-30.142.002-13.471.005-30.237 1.056-46.966a2.62 2.62 0 00-5.231-.329c-1.061 16.892-1.065 33.748-1.066 47.292-.002 13.481-.004 25.124-1.153 28.423-1.912 5.487-6.497 6.424-7.271 6.543h-23.463c-.043 0-.087 0-.129.003-6.332.312-16.336-.251-18.764-2.484-3.394-3.115-2.688-7.267-.482-10.001 2.15-2.664 6.232-3.9 6.274-3.911a2.628 2.628 0 001.858-2.864c-.059-.437-5.962-44.329-10.002-91.975a2.621 2.621 0 00-5.225.442c3.553 41.91 8.545 80.923 9.757 90.174-1.935.846-4.77 2.402-6.746 4.85-4.354 5.394-3.926 12.604 1.016 17.142 3.709 3.407 13.076 3.95 18.669 3.95h-.002z"
        />
        <path
          fill="#371A45"
          d="M167.985 359.24c12.588 0 22.645-2.043 23.373-2.196a2.617 2.617 0 002.031-3.096 2.621 2.621 0 00-3.1-2.03c-.299.062-30.22 6.122-50.721-2.922a2.621 2.621 0 00-3.458 1.337 2.618 2.618 0 001.34 3.454c9.605 4.238 20.825 5.453 30.537 5.453h-.002zM221.716 355.135c17.517 0 28.69-4.017 29.338-4.257a2.617 2.617 0 001.551-3.363 2.62 2.62 0 00-3.363-1.55c-.21.077-21.316 7.602-50.653 1.534a2.618 2.618 0 10-1.062 5.126c8.92 1.847 17.079 2.51 24.19 2.51h-.001zM251.351 381.845c-8.504-6.216-28.007-2.709-38.165-1.474-1.635.258-1.386 2.701.284 2.604 3.071-.223 6.139-.414 9.201-.538 6.727-.227 13.627-.376 20.162 1.103 1.827.449 3.987 1.254 5.247 2.4 2.776 2.23 6.074-1.877 3.27-4.097l.001.002zM143.06 384.314c5.531-1.74 11.702-2.067 17.563-1.899 1.61.042 1.857-2.312.276-2.604-6.308-1.073-12.617-1.629-19.067-.588-3.659.746-2.377 6.09 1.228 5.091z"
        />
        <path
          fill="#D8D8D8"
          d="M141.978 274.387s-25.913 14.076-37.041 3.169c-17.165-16.824 3.305-50.617 3.305-50.617l33.734 47.448h.002z"
        />
        <path
          fill="#371A45"
          d="M116.832 284.297c12.19 0 25.604-7.177 26.398-7.61a2.619 2.619 0 00.886-3.817l-33.734-47.448a2.664 2.664 0 00-2.234-1.102 2.625 2.625 0 00-2.147 1.261c-.879 1.452-21.332 35.772-2.898 53.841 3.669 3.596 8.591 4.871 13.731 4.871l-.002.004zm21.24-10.878c-8.192 3.844-24.202 9.223-31.299 2.269-12.385-12.14-2.697-34.991 1.769-43.804l29.53 41.535z"
        />
        <path
          fill="#fff"
          d="M212.256 213.861c4.384 6.494 10.46 13.591 15.252 18.88 4.352 4.798 7.642 8.11 7.642 8.11s57.237 4 72.985-38.111c1.835-4.904 2.983-9.846 3.599-14.69 3.441-26.695-8.933-50.596-8.933-50.596l-30.914 14.553-8.921 29.615s-6.633 3.169-18.129 6.887c-8.893 2.887-20.698 6.1-34.598 8.437-6.509 1.093-3.671 8.491 2.019 16.915h-.002z"
        />
        <path
          fill="#371A45"
          d="M227.508 232.741c4.352 4.798 7.642 8.11 7.642 8.11s57.237 4 72.985-38.111c1.835-4.904 2.983-9.846 3.599-14.69-3.015 8.333-18.365 42.097-69.164 43.519-6.114.171-11.076.582-15.06 1.172h-.002zM212.256 213.861c13.81-5.027 25.279-14.015 32.58-25.354-8.894 2.887-20.698 6.101-34.599 8.438-6.509 1.093-3.671 8.49 2.019 16.914v.002z"
          opacity="0.29"
        />
        <path
          fill="#D8D8D8"
          d="M148.388 207.642c10.847 6.715 24.087 10.663 38.366 10.663 17.12 0 32.731-5.676 44.542-15.004l-18.431-29.216-64.197 33.065c-.092.165-.19.327-.282.492h.002z"
        />
        <path
          fill="#371A45"
          d="M239.203 243.579c5.537 0 15.853-.487 27.199-3.459 22.158-5.805 37.438-18.413 44.191-36.462 12.207-32.634-4.735-66.004-5.464-67.409a2.623 2.623 0 00-3.533-1.123 2.617 2.617 0 00-1.124 3.528c.208.406 16.572 32.791 5.208 63.171-13.78 36.838-61.954 36.706-69.389 36.46-14.384-14.594-28.666-33.024-27.083-37.942.057-.18.193-.601 1.464-.815 31.853-5.36 52.559-15.133 53.425-15.545a2.62 2.62 0 001.38-1.608l8.919-29.612a2.618 2.618 0 00-1.756-3.262 2.626 2.626 0 00-3.266 1.753l-8.577 28.474c-4.566 2.022-23.84 10.067-50.998 14.637-3.842.645-5.148 3.019-5.586 4.375-1.017 3.165-.721 9.376 13.528 26.725 7.489 9.116 15.467 17.155 15.546 17.235a2.623 2.623 0 001.676.766c.188.014 1.717.114 4.237.114l.003-.001z"
        />
        <path
          fill="#fff"
          d="M129.499 281.346c.668 12.189 3.971 23.233 11.175 31.225 25.082 27.835 92.253 22.395 105.729-12.712 2.209-5.749 3.022-13.609 2.864-22.427-.386-21.76-6.692-49.365-12.558-65.547l-23.844-37.798-64.197 33.065c-10.29 17.825-20.574 48.534-19.171 74.194h.002z"
        />
        <path
          fill="#371A45"
          d="M148.388 207.642c10.847 6.715 24.087 10.663 38.366 10.663 17.12 0 32.731-5.676 44.542-15.004l-18.431-29.216-64.197 33.065c-.092.165-.19.327-.282.492h.002zM129.499 281.346c.668 12.189 3.971 23.233 11.175 31.225 25.082 27.835 92.253 22.395 105.729-12.712 2.209-5.749 3.022-13.609 2.864-22.427-23.889 4.306-82.011 13.02-119.768 3.914z"
          opacity="0.29"
        />
        <path
          fill="#371A45"
          d="M245.932 304.085c11.604-18.737 4.159-52.839-2.09-72.831-.5-1.497-2.808-.981-2.544.628 2.066 11.916 3.808 23.906 4.667 35.936.595 8.913 1.244 17.82-.109 26.634a36.891 36.891 0 01-2.261 8.453c-.547 1.448 1.491 2.488 2.337 1.178v.002zM168.769 329.372c11.465 4.736 25.193 4.61 36.858.617 1.393-.517.981-2.617-.501-2.572-8.662.337-18.172.469-26.772-.084-2.969-.164-5.94-.382-8.99-.509-1.453-.066-1.923 1.969-.595 2.548zM146.395 205.841c-14.709 25.232-26.8 67.718-15.179 95.8 1.977 4.452 4.783 8.638 8.481 11.808a1.322 1.322 0 001.949-1.752c-15.261-23.268-9.458-53.282-1.481-78.197 2.904-8.647 6.435-17.118 10.782-25.06 1.546-2.988-2.704-5.486-4.552-2.601v.002z"
        />
        <path
          fill="#fff"
          d="M136.122 289.301c-4.808 0-8.706-3.893-8.706-8.696 0-4.802 3.898-8.695 8.706-8.695s8.706 3.893 8.706 8.695c0 4.803-3.898 8.696-8.706 8.696z"
        />
        <path
          fill="#371A45"
          d="M136.122 291.919c6.245 0 11.328-5.074 11.328-11.314 0-6.239-5.081-11.314-11.328-11.314-6.247 0-11.328 5.076-11.328 11.314s5.083 11.314 11.328 11.314zm0-17.391c3.355 0 6.084 2.726 6.084 6.077s-2.729 6.077-6.084 6.077c-3.355 0-6.084-2.726-6.084-6.077s2.729-6.077 6.084-6.077z"
        />
        <path
          fill="#fff"
          d="M144.913 306.162l49.134 1.008c5.816.12 10.677-4.535 10.797-10.351l.536-26.115c.104-5.091-3.458-9.441-8.253-10.526a10.437 10.437 0 00-2.11-.258l-49.125-1.007c-5.822-.121-10.683 4.534-10.797 10.35l-.43 20.683-.114 5.432c-.121 5.815 4.548 10.662 10.362 10.784z"
        />
        <path
          fill="#fff"
          d="M144.913 306.162l49.134 1.008c5.816.12 10.677-4.535 10.797-10.351l.536-26.115c.104-5.091-3.458-9.441-8.253-10.526a10.437 10.437 0 00-2.11-.258l-49.125-1.007c-5.822-.121-10.683 4.534-10.797 10.35l-.43 20.683-.114 5.432c-.121 5.815 4.548 10.662 10.362 10.784z"
        />
        <path
          fill="#fff"
          d="M144.913 306.162l49.134 1.008c5.816.12 10.677-4.535 10.797-10.351l.536-26.115c.104-5.091-3.458-9.441-8.253-10.526a10.437 10.437 0 00-2.11-.258l-49.125-1.007c-5.822-.121-10.683 4.534-10.797 10.35l-.43 20.683-.114 5.432c-.121 5.815 4.548 10.662 10.362 10.784z"
        />
        <path
          fill="#371A45"
          d="M144.84 306.165l49.133 1.008c5.816.121 10.677-4.535 10.798-10.35l.535-26.115c.104-5.091-3.458-9.442-8.253-10.526-2.828 1.793-4.624 2.94-4.624 2.94-2.823 20.913-9.015 31.243-35.085 31.134-11.254-.05-18.32-1.909-22.753-4.306l-.114 5.431c-.121 5.815 4.549 10.662 10.363 10.784z"
          opacity="0.29"
        />
        <path
          fill="#371A45"
          d="M192.08 309.748c8.365 0 15.255-6.707 15.428-15.1l.446-21.662c.175-8.499-6.604-15.555-15.114-15.73l-44.669-.917c-4.115-.094-8.011 1.444-10.993 4.302-2.981 2.858-4.67 6.692-4.755 10.794l-.447 21.663c-.174 8.497 6.605 15.553 15.114 15.729l44.67.917c.107.002.213.004.32.004zm-44.229-48.175h.211l44.669.917c2.708.055 5.24 1.17 7.132 3.14 1.891 1.968 2.904 4.543 2.848 7.247l-.446 21.663c-.116 5.61-4.764 10.066-10.398 9.967l-44.669-.917c-5.618-.115-10.096-4.774-9.98-10.385l.446-21.662a10.1 10.1 0 013.144-7.125 10.12 10.12 0 017.043-2.847v.002z"
        />
        <path
          fill="#fff"
          d="M162.295 306.338l.094.188 31.66.647c4.261.082 8.008-2.389 9.75-6.015a10.318 10.318 0 001.037-4.335l.243-11.603.081-3.828.134-6.723.084-3.961c.118-5.817-4.543-10.664-10.361-10.786l-10.616-.213c4.874 1.021 8.51 5.408 8.409 10.544l-.156 7.752-.134 6.357-.242 12.02c-.126 5.802-4.985 10.462-10.799 10.348l-19.182-.392h-.002z"
        />
        <path
          fill="#371A45"
          d="M157.28 306.232l.094.188 36.675.753c4.261.082 8.008-2.389 9.75-6.015a10.318 10.318 0 001.037-4.335l.243-11.603.081-3.828.134-6.723c-2.123-.337-4.953-.102-6.552 3.009-2.219 4.31-4.445 2.813-6.088.329l-.134 6.357-.242 12.02c-.126 5.802-4.985 10.462-10.799 10.348l-24.197-.498-.002-.002z"
          opacity="0.29"
        />
        <path
          fill="#371A45"
          d="M194.274 309.793c3.407 0 6.636-1.301 9.12-3.681 2.551-2.446 3.995-5.727 4.065-9.236l.537-26.118c.074-3.509-1.233-6.846-3.682-9.394a13.13 13.13 0 00-9.246-4.064l-10.615-.215-.594 5.18c3.743.788 6.403 4.126 6.324 7.94l-.537 26.118a7.883 7.883 0 01-2.455 5.566 7.915 7.915 0 01-5.661 2.219l-31.92-.466-2.63-.053-.049 2.633c-.027 1.906 1.291 2.445 2.586 2.604v.052l44.473.912.282.003h.002zm-1.224-47.296l1.91.038a7.934 7.934 0 015.573 2.455 7.876 7.876 0 012.222 5.657l-.537 26.122a7.88 7.88 0 01-2.454 5.564 7.91 7.91 0 01-5.663 2.221l-2.202-.045a13.046 13.046 0 002.991-8.08l.537-26.117a13.126 13.126 0 00-2.378-7.815h.001z"
        />
        <path
          fill="#22A7F0"
          d="M173.163 283.132c-.015.965 1.23 8.782 1.23 8.782l5.51.082c3.793.056 6.927-3.822 6.999-8.662.072-4.839-2.945-8.807-6.739-8.864l-5.51-.083s-1.467 7.17-1.489 8.743l-.001.002z"
        />
        <path
          fill="#1C7AEA"
          d="M173.163 283.132c-.015.965 1.23 8.782 1.23 8.782l5.51.082c3.793.056 6.927-3.822 6.999-8.662.072-4.839-2.945-8.807-6.739-8.864l-5.51-.083s-1.467 7.17-1.489 8.743l-.001.002z"
        />
        <path
          fill="#371A45"
          d="M179.985 293.959c2.411 0 4.658-1.143 6.341-3.225 1.597-1.976 2.5-4.593 2.54-7.369.04-2.778-.784-5.42-2.32-7.444-1.646-2.166-3.904-3.378-6.356-3.414l-3.845-.057h-.059a3.957 3.957 0 00-3.889 3.198c-.782 3.992-1.186 6.5-1.2 7.455-.003.283-.013.947.994 7.452a3.949 3.949 0 003.855 3.349l3.827.057h.114l-.002-.002zm-3.729-17.558l3.875.033c1.219.019 2.384.679 3.283 1.863 1.009 1.326 1.548 3.106 1.521 5.009-.028 1.904-.621 3.666-1.668 4.962-.933 1.155-2.132 1.779-3.335 1.764l-3.827-.057c-.71-4.424-.963-6.436-.975-6.828.013-.545.315-2.597 1.128-6.746h-.002z"
        />
        <path
          fill="#22A7F0"
          d="M174.383 291.91c-3.794-.056-6.811-4.025-6.739-8.865.072-4.839 3.206-8.717 6.999-8.661 3.794.056 6.811 4.025 6.739 8.865-.072 4.84-3.206 8.718-6.999 8.661z"
        />
        <path
          fill="#fff"
          d="M181.39 283.256c-.005.365-.027.719-.067 1.068-.671-3.851-3.338-6.746-6.574-6.795-3.513-.052-6.452 3.264-6.932 7.592a10.571 10.571 0 01-.163-2.072c.072-4.839 3.204-8.713 6.999-8.66 3.795.054 6.809 4.027 6.737 8.867z"
          opacity="0.7"
        />
        <path
          fill="#1C7AEA"
          d="M181.39 283.256c-.072 4.834-3.204 8.714-6.999 8.66-3.795-.06-6.809-4.027-6.737-8.867.01-.762.098-1.505.254-2.206.953 3.279 3.408 5.623 6.317 5.665 3.193.05 5.922-2.689 6.746-6.449.285.99.434 2.068.419 3.195v.002z"
        />
        <path
          fill="#371A45"
          d="M174.472 293.879c4.824 0 8.798-4.726 8.885-10.597.088-5.914-3.805-10.785-8.675-10.858h-.109c-4.824 0-8.799 4.726-8.886 10.597-.087 5.914 3.805 10.786 8.676 10.858h.109zm.102-17.528h.051c2.657.038 4.855 3.186 4.802 6.871-.054 3.663-2.318 6.728-4.955 6.728h-.05c-2.657-.039-4.856-3.187-4.802-6.872.053-3.663 2.318-6.727 4.954-6.727zM160.19 308.251h.007a1.965 1.965 0 001.96-1.97l-.04-13.217a11.062 11.062 0 00-5.003-9.235 7.132 7.132 0 01-3.231-6.111l.319-18.628a1.964 1.964 0 00-1.932-1.996 1.973 1.973 0 00-1.999 1.929l-.319 18.628a11.038 11.038 0 005.002 9.459 7.144 7.144 0 013.232 5.966l.041 13.217a1.964 1.964 0 001.965 1.958h-.002z"
        />
        <path
          fill="#fff"
          d="M247.055 271.372s6.556 2.075 7.39 7.838c1.166 8.072-5.878 11.764-5.878 11.764s-28.609 7.609-43.696.942c0 0-9.795-8.007.51-17.021 0 0 22.239 2.181 41.675-3.523h-.001z"
        />
        <path
          fill="#371A45"
          d="M204.87 291.918c15.087 6.663 43.695-.943 43.695-.943s7.046-3.691 5.88-11.763a8.05 8.05 0 00-1.174-3.162c-.563 1.917-1.594 3.415-2.616 4.518-1.351 1.459-3.132 2.438-5.071 2.887-6.409 1.482-25.919 5.393-38.768 1.462a9.769 9.769 0 01-5.553-4.359l-.037-.063c-2.399 6.487 3.644 11.423 3.644 11.423z"
          opacity="0.29"
        />
        <path
          fill="#371A45"
          d="M221.313 297.272c13.536 0 27.105-3.549 27.929-3.769l.283-.075.26-.136c.355-.186 8.669-4.665 7.255-14.457-1.056-7.304-8.861-9.856-9.193-9.96l-.764-.243-.769.227c-18.699 5.488-40.463 3.45-40.681 3.43l-1.129-.109-.855.746c-3.786 3.312-5.674 6.957-5.609 10.833.101 5.974 4.96 10.014 5.166 10.184l.276.224.325.143c4.983 2.201 11.239 2.962 17.503 2.962h.003zm26.255-8.746c-3.127.786-27.937 6.733-41.267 1.154-.735-.699-2.982-3.077-3.016-6.042-.022-2.008.995-4.035 3.026-6.037 4.873.367 23.493 1.343 40.613-3.468 1.388.618 4.474 2.325 4.926 5.45.785 5.441-3.278 8.32-4.282 8.942v.001z"
        />
        <path
          fill="#fff"
          d="M74.719 112.888c.475 11.297 2.677 22.171 7.73 26.365 3.628 3.012 30.803 5.916 30.803 5.916L99.628 72.866s-1.844.317-4.46.912c-5.432 1.244-14.199 3.721-16.515 7.151-2.517 3.724-4.508 18.162-3.932 31.959h-.002z"
        />
        <path
          fill="#371A45"
          d="M74.719 112.888c.475 11.297 2.677 22.171 7.73 26.365 3.628 3.012 30.803 5.916 30.803 5.916L99.628 72.866s-1.844.317-4.46.912c-3.255 8.288-7.106 20.876-8.232 36.054-.779 10.478-8.028 10.437-12.215 3.056h-.002z"
          opacity="0.29"
        />
        <path
          fill="#371944"
          d="M116.479 148.151l-14.754-78.298-2.54.433c-3.113.53-18.84 3.46-22.707 9.181-3.046 4.507-4.579 18.662-4.473 29.696.089 9.343 1.346 25.937 8.766 32.1 3.931 3.266 25.64 5.81 32.201 6.513l3.507.375zM97.534 75.927l12.484 66.244c-11.647-1.384-24.025-3.513-25.902-4.94-9.906-8.229-7.32-48.879-3.294-54.833 1.707-2.525 10.075-5.1 16.71-6.471h.002z"
        />
        <path
          fill="#fff"
          d="M181.217 205.345c-54.112 0-97.978-43.814-97.978-97.862 0-54.047 43.866-97.861 97.978-97.861 54.113 0 97.979 43.814 97.979 97.861 0 54.048-43.866 97.862-97.979 97.862z"
        />
        <path
          fill="#D8D8D8"
          d="M273.339 140.867c-14.354 39.587-53.392 66.82-97.639 64.325-54.026-3.046-95.353-49.262-92.304-103.222 1.19-21.073 8.97-40.214 21.226-55.524 5.595 49.14 65.328 90.42 140.403 94.651 9.648.543 19.121.451 28.312-.23h.002z"
        />
        <path
          fill="#371A45"
          d="M181.347 207.969c24.733 0 48.289-8.956 66.884-25.544 20.042-17.879 31.913-42.483 33.427-69.279 1.514-26.797-7.509-52.578-25.409-72.596S213.716 8.675 186.887 7.163c-26.824-1.507-52.642 7.5-72.683 25.379C94.162 50.42 82.29 75.025 80.776 101.82c-1.513 26.797 7.51 52.578 25.41 72.596s42.534 31.875 69.362 33.387c1.937.109 3.874.165 5.799.165zm-.19-195.732c1.804 0 3.61.05 5.433.154 52.499 2.96 92.798 48.026 89.833 100.46-2.86 50.614-45.008 89.874-95.145 89.879-1.804 0-3.61-.051-5.433-.153-52.497-2.959-92.796-48.025-89.832-100.46 2.858-50.614 45.007-89.878 95.144-89.878v-.002z"
        />
        <path
          fill="#371A45"
          d="M181.281 207.97c52.902 0 97.363-41.42 100.381-94.82 3.125-55.32-39.389-102.866-94.773-105.987-24.868-1.404-49.25 6.398-68.649 21.965-22.205 17.809-35.859 44.306-37.465 72.694-.03.538-.054 1.083-.076 1.621a100.618 100.618 0 001.499 21.896c5.747 31.959 26.784 59.299 56.275 73.134 11.649 5.464 24.122 8.605 37.076 9.335 1.921.107 3.834.161 5.734.161l-.002.001zm95.146-95.117c-1.435 25.399-12.689 48.719-31.687 65.668-18.999 16.948-43.471 25.498-68.895 24.058-12.281-.692-24.105-3.668-35.143-8.846-27.955-13.115-47.896-39.03-53.343-69.321a95.321 95.321 0 01-1.42-20.754c.02-.51.044-1.029.072-1.541 1.521-26.91 14.465-52.024 35.514-68.905 18.388-14.756 41.5-22.152 65.07-20.82 52.492 2.958 92.789 48.03 89.832 100.461z"
        />
        <path
          fill="#371A45"
          d="M172.986 204.109c53.021 0 97.368-38.77 100.203-88.909 1.427-25.255-7.762-49.583-25.876-68.503-18.066-18.87-42.86-30.098-69.81-31.618-20.898-1.176-41.274 3.602-58.937 13.823-.114.067-.225.141-.328.225-22.205 17.81-35.86 44.305-37.465 72.695-.028.512-.05 1.03-.072 1.541-.032.32-.05.635-.069.957a80.631 80.631 0 001.578 21.091c5.733 31.885 26.77 59.227 56.26 73.062.092.044.188.083.284.114 9.011 3.008 18.455 4.807 28.074 5.35 2.065.115 4.118.174 6.157.174l.001-.002zM171.3 20.141c1.963 0 3.932.056 5.908.168 25.619 1.445 49.17 12.102 66.315 30.01 17.1 17.858 25.776 40.794 24.431 64.586-2.675 47.356-44.708 83.966-94.969 83.969-1.944 0-3.892-.054-5.86-.166-9.102-.513-18.037-2.208-26.562-5.039-27.882-13.136-47.766-39.015-53.206-69.257a75.483 75.483 0 01-1.49-19.773c.017-.287.03-.545.059-.81.005-.057.01-.112.011-.169.022-.512.044-1.031.073-1.543 1.517-26.842 14.4-51.901 35.357-68.78 15.065-8.68 32.215-13.196 49.933-13.196z"
        />
        <path
          fill="#371A45"
          d="M261.084 114.518c-2.589 45.816-44.499 80.722-93.606 77.952-41.626-2.347-75.065-31.012-82.702-67.596a77.803 77.803 0 01-1.527-20.383c.018-.317.035-.625.069-.94 3.096-45.355 44.769-79.755 93.537-77.006 49.105 2.768 86.819 42.155 84.229 87.971v.002z"
        />
        <path
          fill="#220F2D"
          d="M261.084 114.518c-2.589 45.816-44.499 80.722-93.606 77.952-36.932-2.082-67.418-24.879-79.154-55.552 16.564 19.622 41.677 32.825 70.394 34.445 50.859 2.866 94.589-31.626 100.653-78.082a77.592 77.592 0 011.712 21.237h.001z"
        />
        <path
          fill="#fff"
          d="M212.882 61.822s-42.803-12.806-74.701 2.378c-.033.017-.067.032-.1.05a41.6 41.6 0 00-16.543 14.252c-5.463 8.083-8.021 14.449-10.053 20.138-6.174 17.308-5.774 26.571-5.774 26.571-6.747-12.651-8.686-25.01-3.689-39.477a69.014 69.014 0 011.69-4.347 29.1 29.1 0 01.45-1.006c-.007-.007 0-.012.011-.018a101.862 101.862 0 016.308-10.62c8.207-12.084 22.071-26.739 41.901-28.844 37.081-3.938 62.595 19.623 60.5 20.923zM228.231 127.04c-7.396-.508-12.918-7.797-12.334-16.282.583-8.485 7.052-14.952 14.447-14.445 7.396.508 12.918 7.798 12.334 16.282-.583 8.485-7.052 14.952-14.447 14.445zM221.604 143.269c-.086-3.512 2.324-6.419 5.383-6.494 3.059-.075 5.608 2.712 5.694 6.223.086 3.512-2.324 6.419-5.383 6.494-3.059.074-5.608-2.712-5.694-6.223z"
          opacity="0.5"
        />
        <path
          fill="#fff"
          d="M283.849 99.82l-6.675 37.976-4.158 23.656-13.402-.119-1.267-.014c-5.779-1.135-7.826-17.07-4.574-35.566.071-.416.151-.843.232-1.259 3.35-17.866 10.473-31.265 16.118-30.147l1.185.47 12.541 5.004z"
        />
        <path
          fill="#371A45"
          d="M277.174 137.796l-4.158 23.656-13.402-.119-1.267-.014c-5.779-1.135-7.826-17.07-4.574-35.566.071-.416.151-.843.232-1.259l1.01-2.153s.923 8.133 4.609 9.601c3.898 1.561 7.452-7.829 7.452-7.829l10.099 13.683h-.001z"
          opacity="0.29"
        />
        <path
          fill="#371A45"
          d="M273.016 164.074a2.62 2.62 0 002.582-2.166l10.834-61.633a2.616 2.616 0 00-1.61-2.884l-13.724-5.48a2.659 2.659 0 00-.465-.139c-1.229-.243-3.159-.213-5.391 1.314-5.791 3.962-11.304 16.609-14.048 32.218-3.147 17.898-1.875 36.9 6.644 38.589.16.031.324.048.487.05l14.668.131h.023zm7.884-62.609l-10.082 57.351-12.125-.108c-2.664-.98-5.786-12.869-2.335-32.499 2.716-15.443 8.016-26.179 11.848-28.8.292-.2.816-.51 1.25-.512l11.444 4.568z"
        />
        <path
          fill="#fff"
          d="M288.743 128.096s-.01.01-.01.021a88.337 88.337 0 01-.67 4.434c-2.989 17.012-9.723 29.96-15.045 28.901-5.161-1.021-7.1-14.883-4.455-31.297.074-.468.158-.945.242-1.423 2.989-17.012 9.733-29.96 15.047-28.911 4.849.964 6.84 13.22 4.893 28.275h-.002z"
        />
        <path
          fill="#D8D8D8"
          d="M288.733 128.117a88.337 88.337 0 01-.67 4.434c-2.989 17.012-9.723 29.96-15.045 28.901-5.161-1.021-7.1-14.883-4.455-31.297 1.588 6.292 5.158 17.478 10.12 14.975 2.897-1.472 7.009-9.911 10.052-17.012l-.002-.001z"
        />
        <path
          fill="#371A45"
          d="M273.622 164.131c1.093 0 2.478-.304 4.008-1.352 5.373-3.676 10.484-15.365 13.016-29.781 2.823-16.056 2.06-34.09-6.284-35.742-1.17-.232-3.007-.203-5.122 1.242-5.373 3.676-10.484 15.367-13.016 29.781-2.823 16.057-2.061 34.09 6.284 35.743a5.8 5.8 0 001.114.107v.002zm11.86-32.038c-2.488 14.154-7.325 23.978-10.816 26.366-.47.322-.886.48-1.138.426-2.128-.421-5.421-11.034-2.14-29.701 2.488-14.153 7.325-23.975 10.816-26.366.47-.32.886-.478 1.14-.426 2.126.421 5.421 11.036 2.139 29.701h-.001z"
        />
        <path
          fill="#fff"
          d="M312.278 181.619c.104-18.288-2.721-31.421-9.478-44.167-7.508-14.162-33.498-22.879-38.758-13.998-3.662 6.181 3.029 9.963 5.252 11.011a.65.65 0 01.28.93c-1.341 2.188-5.583 9.784-5.688 18.426-.101 8.199 1.925 15.652 11.982 19.025"
        />
        <path
          fill="#371A45"
          d="M314.899 181.634c.118-13.612-1.559-27.614-7.239-40.173-1.044-2.289-2.046-4.429-3.345-6.624-3.639-5.751-9.224-10.018-15.203-13.066-6.071-2.975-12.856-5.218-19.751-4.279-5.45.663-9.933 5.418-8.819 11.193.835 3.832 4.121 6.38 7.301 7.985l.306.149c-.697-.292-1.277-1.294-1.071-2.141.019-.213.173-.518.24-.634l-.02.034-.166.273c-5.415 8.962-8.114 20.489-3.753 30.45 1.495 3.211 4.164 5.995 7.319 7.541 1.529.816 3.144 1.327 4.747 1.745a1.311 1.311 0 00.834-2.483c-4.048-1.836-7.252-4.499-8.691-8.575-2.633-8.725-.312-18.46 4.193-26.22 1.126-1.714.28-4.113-1.615-4.832-2.187-1.14-4.991-3.005-4.501-5.641.5-2.216 2.175-3.327 4.538-3.676 9.49-.989 20.628 4.737 27.088 11.569 2.528 2.699 4.055 6.02 5.571 9.354 2.779 6.236 4.607 12.858 5.624 19.641.893 6.043 1.188 12.206 1.165 18.392.069 3.426 5.124 3.507 5.243.02h.005v-.002z"
        />
        <path fill="#fff" d="M262.968 181.619s-2.879 10.855-2.879 17.208l2.879-17.208z" />
        <path
          fill="#371A45"
          d="M260.433 180.948c-.79 4.436-1.405 8.825-1.651 13.321-.071 1.502-.104 3.012-.004 4.558a1.313 1.313 0 002.622 0c.587-5.562 2.343-11.212 4.104-16.543 1.074-3.567-4.238-4.975-5.071-1.336z"
        />
        <path
          fill="#DDDCDB"
          d="M125.385 181.926c7.8 0 15.148 6.316 16.407 14.107 1.263 7.796-4.044 14.113-11.843 14.113-7.8 0-15.155-6.317-16.412-14.113-1.259-7.791 4.044-14.107 11.848-14.107zm-3.38 12.742c.584 3.616 4.369 6.91 7.942 6.91.834 0 1.991-.183 2.734-1.056.623-.731.853-1.839.641-3.125-.584-3.616-4.369-6.905-7.937-6.905-.841 0-1.995.183-2.739 1.051-.618.731-.846 1.839-.641 3.125z"
        />
        <path
          fill="#fff"
          d="M125.382 181.922c7.806 0 15.152 6.317 16.411 14.114.02.117.039.236.046.354-2.629-5.911-8.784-10.245-15.239-10.245-7.687 0-12.944 6.127-11.895 13.753a15.174 15.174 0 01-1.166-3.862c-1.259-7.797 4.037-14.114 11.843-14.114z"
          opacity="0.7"
        />
        <path
          fill="#371A45"
          d="M129.949 212.765c4.52 0 8.519-1.774 11.264-4.993 2.784-3.267 3.909-7.584 3.167-12.157-1.452-8.992-9.973-16.308-18.995-16.308-4.521 0-8.521 1.772-11.264 4.989-2.784 3.266-3.91 7.582-3.172 12.154 1.452 8.996 9.975 16.315 19 16.315zm-4.564-28.221c6.559 0 12.758 5.341 13.818 11.906.492 3.036-.213 5.851-1.983 7.929-1.731 2.03-4.312 3.149-7.271 3.149-6.563 0-12.765-5.343-13.824-11.911-.49-3.034.217-5.847 1.988-7.926 1.73-2.029 4.313-3.147 7.272-3.147zm4.564 19.654c1.963 0 3.644-.702 4.731-1.98 1.131-1.326 1.568-3.19 1.23-5.245-.78-4.843-5.699-9.1-10.523-9.1-1.964 0-3.644.7-4.732 1.967-1.129 1.338-1.564 3.193-1.237 5.24v.004c.784 4.855 5.705 9.113 10.531 9.113v.001zm-4.564-11.086c2.274 0 4.973 2.374 5.349 4.704.106.641-.013.961-.05 1.013-.03.025-.254.134-.735.134-2.275 0-4.977-2.376-5.354-4.706-.104-.645.018-.972.057-1.024.016-.01.245-.119.733-.119v-.002z"
        />
        <path
          fill="#EDAE3E"
          d="M94.04 196.036l15.088 45.96 13.508 41.169h74.12l-8.363-25.485-20.232-61.644h-74.12z"
        />
        <path
          fill="#D18C28"
          d="M109.128 241.996l13.508 41.169h74.12l-8.363-25.485s-6.489 14.88-27.384 17.636c-11.351 1.492-21.607-6.853-22.494-18.257-.399-5.107-2.012-10.801-6.082-16.104-5.952-7.738-16.044-3.619-23.307 1.041h.002z"
        />
        <path
          fill="#371A45"
          d="M122.634 285.782h74.119a2.618 2.618 0 002.491-3.433L170.65 195.22a2.62 2.62 0 00-2.491-1.804H94.041a2.614 2.614 0 00-2.49 3.433l28.593 87.129a2.62 2.62 0 002.49 1.804zm70.5-5.237h-68.601l-26.875-81.892h68.6l26.874 81.892h.002z"
        />
        <path fill="#DDDCDB" d="M109.529 207.566h44.097l-3.605-11.532h-44.095l3.603 11.532z" />
        <path
          fill="#fff"
          d="M108.054 202.85l1.475 4.715h44.095l-3.605-11.529h-4.844l.819 2.622c.65 2.08-.906 4.192-3.086 4.192h-34.856.002z"
          opacity="0.7"
        />
        <path
          fill="#371A45"
          d="M109.529 210.185h44.097c.834 0 1.618-.396 2.111-1.068.494-.671.64-1.537.392-2.332l-3.604-11.532a2.623 2.623 0 00-2.502-1.839h-44.097a2.62 2.62 0 00-2.503 3.4l3.604 11.532a2.623 2.623 0 002.502 1.839zm40.532-5.237h-38.603l-1.967-6.295h38.603l1.967 6.295z"
        />
        <path
          fill="#fff"
          d="M98.275 242.108c1.043 6.364 2.182 11.672 3.435 16.018 0 0 0 .007.006.027 3.277 11.437 7.281 16.268 12.073 16.261 23.667-.027 24.073-20.477 17.696-32.141-6.981-12.771-24.853-7.836-24.853-7.836l-3.204-9.773s-7.314 4.229-5.15 17.446l-.003-.002z"
        />
        <path
          fill="#371A45"
          d="M101.716 258.152c3.277 11.436 7.281 16.267 12.073 16.261 23.667-.027 24.073-20.478 17.696-32.142 0 0 3.63 18.427-10.251 22.794-14.798 4.649-19.295-6.343-19.518-6.913z"
          opacity="0.29"
        />
        <path
          fill="#371A45"
          d="M113.777 277.031h.012c12.469-.013 18.266-5.376 20.935-9.874 4.8-8.092 2.987-18.958-.938-26.138-6.308-11.537-19.819-10.588-25.395-9.635l-2.474-7.54a2.626 2.626 0 00-3.805-1.452c-.362.21-8.853 5.306-6.425 20.139 3.908 23.855 9.488 34.499 18.09 34.499v.001zm.732-40.953c5.002 0 11.344 1.36 14.674 7.45 3.11 5.688 4.759 14.674 1.031 20.959-2.872 4.839-8.399 7.299-16.43 7.305h-.003c-3.644 0-8.841-5.225-12.917-30.106-.977-5.96.161-9.743 1.456-11.988l1.822 5.552a2.621 2.621 0 003.189 1.709c.28-.076 3.333-.881 7.178-.881zM284.743 231.908a1.965 1.965 0 00.658-3.816c-23.591-8.358-21.253-42.894-21.227-43.241a1.964 1.964 0 00-1.811-2.107 1.96 1.96 0 00-2.109 1.807c-.119 1.542-2.605 37.877 23.833 47.243.217.077.44.114.658.114h-.002z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1006_375870">
        <path fill="#fff" d="M0 0H425V427H0z" transform="translate(4 4)" />
      </clipPath>
      <clipPath id="clip1_1006_375870">
        <path fill="#fff" d="M0 0H314.651V414H0z" />
      </clipPath>
      <clipPath id="clip2_1006_375870">
        <path fill="#fff" d="M0 0H243V399H0z" transform="matrix(-1 0 0 1 315 7)" />
      </clipPath>
    </defs>
  </svg>
);
