import { Modal, Text, Icon, Button } from '@solides/management-util-ui';
import { ModalFeatureToggleProps } from './types';
import * as styles from './styles';
import { useState, useEffect } from 'react';

export function ModalFeatureToggle({ data }: ModalFeatureToggleProps) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(data.show);
  }, [data]);

  const handleClose = () => setShow(false);

  return (
    <Modal showCloseIcon={false} size="md" show={show}>
      <div className="flex flex-col gap-y-8 justify-center">
        <Icon
          face={data.icon || 'warning'}
          size="xl"
          variant="filled"
          className={`text-center text-${data.colorIcon || 'warning'}`}
        />
        <Text
          variant="h4"
          className="text-center text-black"
          data-testid="title-modal-feature-toggle"
          data-cy="title-modal-feature-toggle"
        >
          {data.title}
        </Text>

        <Text
          variant="body1"
          className={`text-gray-600 ${data?.messageClassnames || 'text-center'}`}
          data-testid="description-modal-feature-toggle"
          data-cy="description-modal-feature-toggle"
        >
          <span dangerouslySetInnerHTML={{ __html: data.message }} />
        </Text>

        <div className={styles.containerButtons}>
          <Button
            size="lg"
            type="button"
            className="justify-center w-80"
            color="primary"
            data-cy="confirm-modal-feature-toggle-button"
            data-testid="confirm-modal-feature-toggle-button"
            disabled={false}
            variant="contained"
            onClick={handleClose}
          >
            Fechar
          </Button>
        </div>
      </div>
    </Modal>
  );
}
