import { ButtonHTMLAttributes } from 'react';

import { Button } from '@solides/management-util-ui';
import { Spinner } from 'components/atoms';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

export const OfflineCandidacyButton = ({ className, onClick, ...rest }: ButtonProps) => (
  <Button
    size="lg"
    color="primary"
    className={`flex-1 justify-center h-16 ${className}`}
    data-cy="candidacy-button"
    onClick={onClick}
    {...rest}
  >
    Quero me candidatar
  </Button>
);

export const ReviewCandidacyButton = ({ className, ...rest }: ButtonProps) => (
  <Button
    {...rest}
    size="lg"
    color="primary"
    className={`flex-1 justify-center ${className}`}
    variant="outlined"
    iconLeft="edit"
  >
    Candidatura revisada
  </Button>
);

export const QuickCandidacyButton = ({ isLoading, className, ...rest }: ButtonProps) => (
  <Button
    {...rest}
    size="lg"
    color="primary"
    className={`flex-1 justify-center ${className}`}
    iconLeft={isLoading ? '' : 'bolt'}
  >
    {isLoading ? <Spinner /> : 'Candidatura rápida'}
  </Button>
);
