import React from 'react';
import { Button, Icon, Modal, Text } from '@solides/management-util-ui';

const Title = () => (
  <div className="flex flex-col items-center gap-y-5 w-full">
    <Icon face="error" size="xl" variant="filled" className="text-red-500 text-[54px]" />
    <Text variant="h5" className="text-center">
      Ops! Algo deu errado.
    </Text>
  </div>
);

export const ModalStatusError = ({ show, onClose, typeModal }) => {
  const textAddOn = typeModal === 'talentbank' && ' no banco de talentos';
  return (
    <Modal
      size="sm"
      show={show}
      title={<Title />}
      onClose={onClose}
      showCloseIcon={false}
      confirmButton={
        <Button
          size="lg"
          color="primary"
          className="flex-1 justify-center mb-3"
          variant="contained"
          onClick={onClose}
          data-cy="modal-talentBank-error"
        >
          Ok, entendi
        </Button>
      }
    >
      <Text variant="body1" className="text-center text-gray-600 mb-5">
        Não foi possível concluir sua candidatura{textAddOn}. Por favor, tente novamente.
      </Text>
    </Modal>
  );
};
