import { Icon } from '@solides/management-util-ui';
import { TestimonialCard } from 'components/molecules/TestimonialCard';

import { useEffect, useRef, useState } from 'react';

import { Testimonial } from 'types/company';
interface TestimonialsProps {
  testimonials: Testimonial[];
}

export const Testimonials = ({ testimonials }: TestimonialsProps) => {
  const contentRef = useRef<HTMLUListElement>();
  const [count, setCount] = useState<number>(0);
  const [isSimple, setIsSimple] = useState(true);

  const testimonialsSort = [...testimonials].sort((a, b) => a.order - b.order);

  useEffect(() => {
    setIsSimple(testimonialsSort.length <= 2);
  }, [testimonialsSort]);

  useEffect(() => {
    if (!isSimple) {
      const cardWidth = document.getElementById('feedback-1').scrollWidth;
      const pixels = 12 * count;
      contentRef.current.scrollLeft = cardWidth * count + pixels;
    }
  }, [isSimple, count]);

  if (isSimple) {
    return (
      <ul className="max-w-[1100px] flex flex-col mx-auto md:flex-row justify-center gap-x-6 gap-y-4 px-4">
        {testimonialsSort.map(testimonial => (
          <li className="w-full max-w-full md:max-w-lg" key={testimonial.name}>
            <TestimonialCard {...testimonial} />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div>
      <div className="max-w-[1100px] mx-auto flex items-center justify-center gap-x-6">
        <button
          type="button"
          disabled={count <= 0}
          onClick={() => setCount(state => state - 1)}
          className="hidden md:flex p-1 disabled:opacity-40"
          data-cy="testimonials-button-prev"
          data-testid="testimonials-button-prev"
          aria-label="Ação de voltar um testemunho"
        >
          <Icon face="arrow_circle_left" variant="outlined" className="text-primary m-auto" />
        </button>

        <div className="max-w-full md:max-w-[90%] xl:max-w-full">
          <ul className="flex gap-x-3 snap-x overflow-x-hidden scroll-smooth" ref={contentRef}>
            {testimonialsSort.map((testimonial, index) => (
              <li
                id={`feedback-${index}`}
                key={testimonial.name}
                className="w-full md:w-[50%] flex-none snap-start"
              >
                <TestimonialCard {...testimonial} />
              </li>
            ))}
          </ul>
        </div>

        <button
          type="button"
          onClick={() => setCount(state => state + 1)}
          disabled={count >= testimonialsSort.length - 2}
          className="hidden md:flex p-1 disabled:opacity-40"
          data-cy="testimonials-button-next"
          data-testid="testimonials-button-next"
          aria-label="Ação de avançar um testemunho"
        >
          <Icon face="arrow_circle_right" variant="outlined" className="text-primary m-auto" />
        </button>
      </div>

      <div className="flex justify-center flex-wrap gap-x-1 sm:gap-x-2 mt-4" data-cy="testimonial-buttons">
        {testimonialsSort.map((testimonial, index) => (
          <button
            type="button"
            key={testimonial.name}
            className="flex md:last:hidden p-1"
            onClick={() => setCount(index)}
            data-cy="testimonials-button-bullet"
            data-testid="testimonials-button-bullet"
            aria-label={`Selecionar o testemunho ${index + 1}`}
          >
            <span className={'w-3 h-3 rounded-full bg-[#90ACF7]'.concat(count === index ? '' : '/40')} />
          </button>
        ))}
      </div>
    </div>
  );
};
