import { GA_TRACKING_ID } from 'utils/gtag';

export const Gtm = () => {
  const script = document.createElement('script');
  const js = `
  <!-- Google Tag Manager -->
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${GA_TRACKING_ID}');
    function gtag(){dataLayer.push(arguments);}
  <!-- End Google Tag Manager -->
  `;

  const ele = document.getElementById('gtm');

  if (ele) {
    document.head.removeChild(ele);
  }

  script.type = 'text/javascript';
  script.id = 'gtm';
  script.append(js);
  document.head.appendChild(script);
};

export const Analytics = () => {
  const script = document.createElement('noscript');
  const js = `
  <!-- Google Tag Manager (noscript) -->
  <iframe src="https://www.googletagmanager.com/ns.html?id=${GA_TRACKING_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
  <!-- End Google Tag Manager (noscript) -->
  `;

  const ele = document.getElementById('tag-manager');

  if (ele) {
    document.body.removeChild(ele);
  }

  script.id = 'analytics';
  script.innerHTML = js;
  document.body.appendChild(script);
};
