const container = ['bg-white', 'py-6', 'sm:py-7', 'px-4', 'mt-auto'].join(' ');

const medias = [
  'flex',
  'w-full',
  'sm:w-auto',
  'justify-center',
  'md:justify-end',
  'items-center',
  'gap-x-3',
  'md:ml-4',
].join(' ');

export { container, medias };
