const container = ['w-full', 'p-6'].join(' ');

const content = [
  'flex',
  'flex-col',
  'md:flex-row',
  'items-stretch',
  'md:items-end',
  'gap-y-4',
  'md:gap-y-0',
  'gap-x-0',
  'md:gap-x-3',
  'lg:gap-x-5',
].join(' ');

const field__title = ['flex-1'].join(' ');

const field__location = [].join(' ');

const button = ['w-full', 'md:w-44', 'justify-center'].join(' ');

export { container, content, button, field__title, field__location };
