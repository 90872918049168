import { Button, Modal, Radio, TextField } from '@solides/management-util-ui';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Spinner } from '../Spinner';
import useStore from 'store/index';
import { twMerge } from 'tailwind-merge';
import cookies from 'utils/cookies';
import { modalRecommendationSchema } from './validation-schema';

function ReviewButton({ onClick, ...rest }) {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      className="flex-1 md:flex-none justify-center max-h-8 md:max-h-9 text-button-sm md:text-button-md px-3 py-1 md:px-4 md:py-1.5"
      {...rest}
    >
      Revisar
    </Button>
  );
}

function ConfirmButton({ onClick, isLoading = false, ...rest }) {
  return (
    <Button
      color="primary"
      variant="contained"
      onClick={onClick}
      {...rest}
      className={twMerge(
        'justify-center max-h-8 md:max-h-9 text-button-sm md:text-button-md px-3 py-1 md:px-4 md:py-1.5',
        isLoading && 'pointer-events-none'
      )}
    >
      {isLoading ? <Spinner /> : 'Efetuar candidatura'}
    </Button>
  );
}

export function ModalRecommendation({ show, onClose, onConfirm, onReview, vacancyId }) {
  const [loading, setLoading] = useState(false);
  const blocks = useStore(store => store.session.user.recommendation.blocks);

  const windowName = 'jobs-vacancy-' + String(vacancyId);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    trigger,
    clearErrors,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(modalRecommendationSchema(blocks)),
    defaultValues: { hasRecommendation: 'nao', name: '', email: '' },
  });

  const [hasRecommendation, name, email] = watch(['hasRecommendation', 'name', 'email']);

  useEffect(() => {
    const applyData = cookies.get(windowName);

    if (applyData) {
      const { recommendation } = JSON.parse(applyData);
      if (recommendation) {
        const { name, email } = recommendation;
        setValue('name', name);
        setValue('email', email);
        setValue('hasRecommendation', 'sim');
      }
    }
  }, []);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue('hasRecommendation', value);
    if (value === 'nao') {
      reset({ hasRecommendation: 'nao', name: '', email: '' });
      clearErrors(['name', 'email']);
    }
  };

  const handleClose = () => {
    reset({ hasRecommendation: 'nao', name: '', email: '' });
    cookies.remove(windowName);

    onClose();
  };

  const onSubmit = async ({ hasRecommendation, name, email }) => {
    const recommendation = hasRecommendation === 'sim' ? { name, email } : null;
    setLoading(true);
    await onConfirm(recommendation);
    setLoading(false);
    handleClose();
  };

  const captions = {
    name: errors.name ? [{ text: errors.name.message, color: 'error' }] : [],
    email: errors.email ? [{ text: errors.email.message, color: 'error' }] : [],
  };

  const canSubmit = (hasRecommendation === 'sim' && !!name && !!email) || hasRecommendation === 'nao';

  const makeRecommendationValues = () => {
    const { email, hasRecommendation, name } = getValues();

    return hasRecommendation === 'sim' ? { email, name } : null;
  };

  return (
    <Modal
      show={show}
      size="lg"
      title="Você possui indicação?"
      onClose={handleClose}
      cancelButton={
        <ReviewButton
          onClick={() => onReview({ recommendation: makeRecommendationValues() })}
          disabled={loading}
        />
      }
      confirmButton={
        <ConfirmButton onClick={handleSubmit(onSubmit)} isLoading={loading} disabled={!canSubmit} />
      }
    >
      <div className="mb-6">
        <div className="grid grid-cols-2 md:flex">
          <Radio
            {...register('hasRecommendation')}
            label="Não"
            value="nao"
            checked={hasRecommendation === 'nao'}
            onChange={handleChangeRadio}
            disabled={loading}
            id="hasRecommendation_no"
          />
          <Radio
            {...register('hasRecommendation')}
            label="Sim"
            value="sim"
            checked={hasRecommendation === 'sim'}
            onChange={handleChangeRadio}
            disabled={loading}
            id="hasRecommendation_yes"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <TextField
            label="Nome"
            placeholder="Nome do responsável pela indicação"
            {...register('name')}
            disabled={hasRecommendation === 'nao' || loading}
            captions={captions.name}
            color={captions.name.length ? 'error' : 'default'}
            onBlur={() => trigger('name')}
          />
          <TextField
            label="E-mail"
            placeholder="Email do responsável pela indicação"
            {...register('email')}
            disabled={hasRecommendation === 'nao' || loading}
            captions={captions.email}
            color={captions.email.length ? 'error' : 'default'}
            onBlur={() => trigger('email')}
          />
        </div>
      </div>
    </Modal>
  );
}
