import { ButtonHTMLAttributes } from 'react';
import { Spinner } from '../Spinner';
import { useCompany } from 'hooks';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  text?: string;
  className?: string;
}
export const Button = ({ text, isLoading, className, onClick, ...rest }: ButtonProps) => {
  const { colorCompany, colorTextCompany } = useCompany();

  const colorButton = colorCompany && colorCompany !== 'null' ? `bg-[${colorCompany}]` : 'bg-primary';
  const colorText =
    colorTextCompany && colorTextCompany !== 'null' ? `text-[${colorTextCompany}]` : 'text-white';

  return (
    <button
      {...rest}
      onClick={onClick}
      className={`flex-1 justify-center items-center rounded-lg max-h-12 w-full ${colorButton} hover:${colorButton}-700 hover:rounded-lg hover:shadow-none disabled:shadow-none disabled:text-state-disabled/[0.8] hover:disabled:text-state-disabled/[0.8] transition duration-300 ease-in-out border-transparent disabled:bg-state-disabled-background/[0.24] hover:disabled:bg-state-disabled-background/[0.24] shadow-md-${
        `[${colorCompany}]` || 'primary'
      } ${colorText} hover:bg-opacity-90 hover:${colorButton}-500 font-bold ${className}`}
    >
      {isLoading ? <Spinner /> : text}
    </button>
  );
};
