import { fetchFeatures } from './firebase';

const featuresCache: { [key: string]: object } = {};

export const fetchFeaturesWithCache = async (isBrowser: boolean) => {
  if (featuresCache['features']) {
    return featuresCache['features'];
  }

  const features = await fetchFeatures(isBrowser);
  featuresCache['features'] = features;
  return features;
};
