import { IconProps } from './type';

export const IconSolides = ({ className = '' }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="134"
    height="60"
    viewBox="0 0 134 60"
    fill="none"
  >
    <path
      d="M32.9259 46.9522C29.6169 46.9522 26.8419 45.779 24.5779 43.4325C22.3254 41.086 21.1992 38.2128 21.1992 34.8247C21.1992 31.4965 22.3254 28.6472 24.5779 26.2888C26.8303 23.9303 29.6169 22.7451 32.9259 22.7451C36.2349 22.7451 39.0214 23.9303 41.2855 26.2888C43.5611 28.6472 44.6874 31.4965 44.6874 34.8247C44.6874 38.2247 43.5611 41.098 41.3087 43.4325C39.0562 45.779 36.2581 46.9522 32.9259 46.9522ZM28.2004 40.1043C29.4311 41.493 31.0101 42.1754 32.9259 42.1754C34.8416 42.1754 36.4206 41.4811 37.6746 40.1043C38.9285 38.7156 39.5555 36.9557 39.5555 34.8247C39.5555 32.7536 38.9285 31.0057 37.6746 29.593C36.4206 28.1803 34.8416 27.474 32.9259 27.474C31.0101 27.474 29.4311 28.1803 28.2004 29.5691C26.9697 30.9698 26.3427 32.7177 26.3427 34.8247C26.3427 36.9677 26.958 38.7275 28.2004 40.1043Z"
      fill="#80297D"
    />
    <path
      d="M18.2982 36.5127C18.0196 35.8782 17.6132 35.3156 17.1139 34.8606C16.7192 34.5015 16.2548 34.2142 15.7555 33.9987C14.0952 33.3163 11.7615 32.8254 9.82252 32.5261C8.12738 32.2747 6.9315 31.9994 6.23486 31.7001C5.53823 31.4008 5.18992 30.8261 5.18992 29.9881C5.18992 28.324 6.52513 27.3782 9.18394 27.4979C11.8195 27.6177 13.8282 28.4557 15.593 29.8803C15.8948 30.1677 16.2896 30.3472 16.5915 29.8803L18.6349 27.1268C18.9368 26.7198 18.902 26.3726 18.542 26.0853C16.2083 23.8585 13.1315 22.7451 9.32327 22.7451C6.68768 22.7451 4.47006 23.3916 2.68204 24.6726C0.894013 25.9536 0 27.8212 0 30.2754C0 31.3768 0.18577 32.3465 0.545697 33.1965C0.545697 33.2085 0.545698 33.2085 0.557308 33.2205C0.835961 33.855 1.24233 34.4177 1.74158 34.8726C2.13634 35.2318 2.60076 35.5191 3.10002 35.7346C4.76033 36.417 7.09405 36.9078 9.03301 37.2071C10.7281 37.4585 11.924 37.7339 12.6207 38.0332C13.3173 38.3325 13.6656 38.9071 13.6656 39.7571C13.6656 41.4212 12.3304 42.367 9.67159 42.2473C7.03599 42.1276 5.02737 41.2895 3.26256 39.8649C2.96069 39.5775 2.56593 39.398 2.26406 39.8649L0.2206 42.6184C-0.0812745 43.0374 -0.0464408 43.3726 0.313486 43.6599C2.64721 45.8867 5.724 47.0001 9.53226 47.0001C12.1679 47.0001 14.3855 46.3536 16.1735 45.0726C17.9615 43.7916 18.8555 41.924 18.8555 39.4698C18.8555 38.3684 18.6698 37.3987 18.3098 36.5487C18.2982 36.5247 18.2982 36.5127 18.2982 36.5127Z"
      fill="#80297D"
    />
    <path
      d="M133.443 36.5127C133.164 35.8782 132.758 35.3156 132.258 34.8606C131.864 34.5015 131.399 34.2142 130.9 33.9987C129.24 33.3163 126.906 32.8254 124.967 32.5261C123.272 32.2747 122.076 31.9994 121.379 31.7001C120.683 31.4008 120.334 30.8261 120.334 29.9881C120.334 28.324 121.67 27.3782 124.328 27.4979C126.964 27.6177 128.973 28.4557 130.738 29.8803C131.039 30.1677 131.434 30.3472 131.736 29.8803L133.779 27.1268C134.081 26.7198 134.047 26.3726 133.687 26.0853C131.353 23.8585 128.276 22.7451 124.468 22.7451C121.832 22.7451 119.615 23.3916 117.827 24.6726C116.039 25.9536 115.145 27.8212 115.145 30.2754C115.145 31.3768 115.33 32.3465 115.69 33.1965C115.69 33.2085 115.69 33.2085 115.702 33.2205C115.98 33.855 116.387 34.4177 116.886 34.8726C117.281 35.2318 117.745 35.5191 118.245 35.7346C119.905 36.417 122.239 36.9078 124.178 37.2071C125.873 37.4585 127.069 37.7339 127.765 38.0332C128.462 38.3325 128.81 38.9071 128.81 39.7571C128.81 41.4212 127.475 42.367 124.816 42.2473C122.181 42.1276 120.172 41.2895 118.407 39.8649C118.105 39.5775 117.71 39.398 117.409 39.8649L115.365 42.6184C115.063 43.0374 115.098 43.3726 115.458 43.6599C117.792 45.8867 120.869 47.0001 124.677 47.0001C127.312 47.0001 129.53 46.3536 131.318 45.0726C133.106 43.7916 134 41.924 134 39.4698C134 38.3684 133.814 37.3987 133.454 36.5487C133.454 36.5247 133.454 36.5127 133.443 36.5127Z"
      fill="#80297D"
    />
    <path
      d="M51.2501 13.0117H47.6973C47.2793 13.0117 47.0703 13.2152 47.0703 13.6343V46.3773C47.0703 46.7963 47.2793 46.9998 47.6973 46.9998H51.2501C51.6681 46.9998 51.8771 46.7963 51.8771 46.3773V13.6343C51.8655 13.2272 51.6681 13.0117 51.2501 13.0117Z"
      fill="#80297D"
    />
    <path
      d="M86.2673 13.0117H82.7145C82.2965 13.0117 82.0875 13.2152 82.0875 13.6343V25.0793C80.1021 23.5589 77.78 22.7927 75.1212 22.7927C71.8122 22.7927 69.0373 23.9779 66.7732 26.3364C64.5208 28.6949 63.3945 31.5442 63.3945 34.8723C63.3945 38.2723 64.5208 41.1456 66.7732 43.4801C69.0256 45.8266 71.8122 46.9998 75.1212 46.9998C77.7916 46.9998 80.1137 46.2456 82.0875 44.7491C82.5868 44.378 83.0512 43.947 83.504 43.4801C85.7564 41.1336 86.8827 38.2604 86.8827 34.8723V13.6343C86.8827 13.2272 86.6737 13.0117 86.2673 13.0117ZM79.8699 40.1639C78.616 41.5526 77.0369 42.235 75.1212 42.235C73.2054 42.235 71.6264 41.5407 70.3957 40.1639C69.165 38.7752 68.538 37.0153 68.538 34.8843C68.538 32.7773 69.1534 31.0294 70.3957 29.6287C71.6264 28.2279 73.2054 27.5336 75.1212 27.5336C77.0369 27.5336 78.616 28.2399 79.8699 29.6646C81.1238 31.0772 81.7508 32.8251 81.7508 34.8963C81.7392 37.0153 81.1122 38.7871 79.8699 40.1639Z"
      fill="#80297D"
    />
    <path
      d="M59.4005 22.8286H55.8477C55.4297 22.8286 55.2207 23.0321 55.2207 23.4512V46.3773C55.2207 46.7963 55.4297 46.9998 55.8477 46.9998H59.4005C59.8185 46.9998 60.0275 46.7963 60.0275 46.3773V23.4512C60.0275 23.0321 59.8185 22.8286 59.4005 22.8286Z"
      fill="#80297D"
    />
    <path
      d="M109.647 25.9538C107.452 23.5954 104.712 22.4102 101.415 22.4102C98.0246 22.4102 95.1684 23.6193 92.8463 26.0257C90.5126 28.432 89.3516 31.3531 89.3516 34.801C89.3516 38.2489 90.5242 41.1341 92.8696 43.4567C95.2149 45.7792 98.0943 46.9525 101.519 46.9525C105.455 46.9525 108.799 45.6116 111.528 42.9299C111.818 42.5827 111.818 42.2595 111.528 41.9722L109.136 39.3862C108.846 39.0391 108.521 39.0391 108.161 39.3862C106.466 41.2658 104.306 42.2116 101.67 42.2116C99.8127 42.2116 98.2452 41.613 96.9565 40.4158C95.6677 39.2186 94.9246 37.6623 94.7389 35.7588H112.166C112.619 35.7588 112.851 35.5313 112.851 35.0884C112.909 31.3531 111.841 28.3123 109.647 25.9538ZM94.9246 32.5503C95.3194 30.9221 96.0857 29.6172 97.2467 28.6236C98.3962 27.6299 99.7894 27.139 101.427 27.139C102.959 27.139 104.283 27.6419 105.409 28.6475C106.535 29.6531 107.243 30.9461 107.534 32.5503H94.9246Z"
      fill="#80297D"
    />
    <path
      d="M41.1702 20.3146H37.6406V16.6512C37.6406 14.6399 39.2197 13.0117 41.1702 13.0117C43.1208 13.0117 44.6998 14.6399 44.6998 16.6512V16.6871C44.6998 18.6864 43.1208 20.3146 41.1702 20.3146Z"
      fill="#80297D"
    />
    <path
      d="M57.6253 20.3148C55.6747 20.3148 54.0957 18.6866 54.0957 16.6754V16.6394C54.0957 14.6282 55.6747 13 57.6253 13C59.5759 13 61.1549 14.6282 61.1549 16.6394V16.6754C61.1549 18.6866 59.5759 20.3148 57.6253 20.3148Z"
      fill="#80297D"
    />
  </svg>
);
