import Image from 'next/image';
import { Icon } from '@solides/management-util-ui';

import { Testimonial } from 'types/company';

export const TestimonialCard = (testimonial: Testimonial) => {
  return (
    <article className="h-full w-full py-5 md:py-6 px-4 md:px-8 bg-white rounded-xl shadow-sm shadow-gray-400">
      <div className="grid grid-cols-[45px_1fr] md:grid-cols-[60px_1fr] gap-x-2" data-cy="testimonial-name">
        <div
          className="w-[45px] md:w-[60px] h-[45px] md:h-[60px] rounded-full relative overflow-hidden"
          data-cy="testimonial-photo"
        >
          {testimonial?.url ? (
            <Image
              layout="fill"
              objectFit="cover"
              src={testimonial?.url}
              loading="lazy"
              alt={`Imagem de avatar do usuário ${testimonial?.name}`}
            />
          ) : (
            <div className="bg-indigo-900 h-full flex items-center justify-center">
              <Icon face="person_outline" className="text-xl text-white" />
            </div>
          )}
        </div>

        <div className="w-full h-full self-center flex flex-col ml-2.5">
          <strong className="text-xs md:text-xs text-gray-800">{testimonial?.name}</strong>
          {testimonial?.role && (
            <span className="text-gray-600 font-semi-bold text-subtitle2">{testimonial?.role}</span>
          )}
        </div>
      </div>

      <span
        className="block text-body2 text-gray-800 mt-5"
        data-cy="testimonial-text"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: testimonial?.text }}
      />
    </article>
  );
};
