export const GallerySkeleton = (): JSX.Element => (
  <div
    data-testid="gallery-loader"
    className="animate-pulse flex flex-col gap-4 p-6 sm:flex-row"
    role="listitem"
  >
    <div className="bg-slate-50 rounded w-full h-[185px] sm:min-h-[370px]" />
    <div className="flex flex-col gap-4 max-h-[370px]">
      <div className="bg-slate-50 rounded w-full h-[185px] sm:min-w-[450px]" />
      <div className="bg-slate-50 rounded w-full h-[185px]" />
    </div>
  </div>
);
