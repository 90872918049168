/* eslint-disable prettier/prettier */
import { Vacancy } from 'types/vacancy';

export function useAddress(vacancy: Vacancy) {
  const cityName = vacancy?.address?.city?.name || vacancy?.address?.foreign_city;
  const stateCode = vacancy?.address?.state?.code || vacancy?.address?.foreign_state;
  const country = vacancy?.address?.country ? vacancy?.address?.country.name : '';

  const hasCityAndState = cityName && stateCode ? true : false;
  const hasSomeInfor =
    cityName ||
    stateCode ||
    vacancy?.address?.street_address ||
    vacancy?.address?.number ||
    vacancy?.address?.additional_information ||
    vacancy?.address?.neighborhood ||
    vacancy?.address?.zip_code;

  let simpleAddress = '';
  if (hasCityAndState) {
    simpleAddress = hasCityAndState ? `${cityName || ''} - ${stateCode || ''}` : '';
  } else {
    simpleAddress = cityName ? `${cityName || ''}` : '' || stateCode ? `${stateCode || ''}` : '';
  }

  const simpleAddressWithCountry = hasCityAndState
    ? `${country || ''}` + ', ' + simpleAddress
    : `${country || ''}`;

  let showLocation = false;
  if (vacancy?.jobType === 'remoto') {
    showLocation = false;
  } else {
    showLocation = simpleAddressWithCountry ? true : false;
  }
  const showCardAddress = vacancy?.jobType !== 'remoto' && hasSomeInfor;

  const addressParts = [
    vacancy?.address?.street_address,
    vacancy?.address?.number,
    vacancy?.address?.additional_information,
    vacancy?.address?.neighborhood,
    simpleAddress,
    vacancy?.address?.zip_code,
  ];

  const address = addressParts.filter(Boolean).join(', ');

  return { showLocation, showCardAddress, address, simpleAddress, simpleAddressWithCountry };
}
