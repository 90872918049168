import Image from 'next/image';
import { ModalVideoPlayer } from 'components/atoms';
import { thumbnail } from 'utils/thumbnail';
import { IconPlayer } from 'assets';
import { useMemo, useState } from 'react';

type Props = {
  urlVideo: string;
  title: string;
};

export const VideoPlayer = ({ urlVideo, title }: Props) => {
  const [showPlayer, setShowPlayer] = useState(false);

  const normalizedYoutubeUrl = useMemo(() => {
    const videoId = urlVideo.includes('v=')
      ? urlVideo.split('v=')[1].split('&')[0]
      : urlVideo.split('/').pop().split('?')[0];

    return `https://www.youtube.com/watch?v=${videoId}`;
  }, [urlVideo]);

  return (
    <>
      <div
        className="relative h-full min-h-[315px] cursor-pointer flex"
        onClick={() => setShowPlayer(true)}
        data-cy="video-player"
        aria-hidden
      >
        <Image
          src={thumbnail(normalizedYoutubeUrl)}
          width={800}
          height={400}
          className="bg-blend-multiply h-full"
          alt="Executar vídeo"
        />
        <div className="bg-black opacity-30 w-full h-full absolute z-2 top-0 left-0" />
        <div className="absolute z-2 top-0 left-0 flex justify-center items-center w-full h-full">
          <IconPlayer color="#fff" />,
        </div>
      </div>

      <ModalVideoPlayer show={showPlayer} title={title} src={urlVideo} onClose={() => setShowPlayer(false)} />
    </>
  );
};
