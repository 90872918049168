import { Text } from '@solides/management-util-ui';

interface IVacancyQuantity {
  paginationCurrent: number;
  paginationTake: number;
  paginationTotal: number;
}

export const VacancyQuantity = ({
  paginationCurrent,
  paginationTake,
  paginationTotal,
}: IVacancyQuantity): JSX.Element => {
  const pageStartVacancy = paginationCurrent == 1 ? 1 : (paginationCurrent - 1) * paginationTake + 1;
  const pageEndVacancy =
    paginationCurrent * paginationTake > paginationTotal
      ? paginationTotal
      : paginationCurrent * paginationTake;

  return (
    <>
      <h1 className="text-md text-gray-800 font-bold">Vagas</h1>
      <Text
        variant="body2"
        data-cy="vacancy-description"
        className="text-body2 text-gray-500"
        title="Quantidade de itens"
      >
        Mostrando {pageStartVacancy}-{pageEndVacancy} de <strong>{paginationTotal} vagas encontradas</strong>
      </Text>
      <hr className="border-0 border-t-[1px] border-t-gray-500/25 mt-[18px] mb-6" />
    </>
  );
};
