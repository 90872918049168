import { Text } from '@solides/management-util-ui';
import { Button } from 'components/atoms';
import useStore from 'store';
import { useApplyTalentBank } from 'hooks/useApplyTalentBank';
import { useScrollToElement } from 'hooks';

export const TalentBank = () => {
  const sectionRef = useScrollToElement('#banco-de-talentos');
  const { onApply, hasApplied } = useApplyTalentBank();
  const { loading } = useStore(store => store.session);
  const { company } = useStore(store => store.company);

  return (
    <section className="bg-[#212B36] p-6 md:p-12" id="banco-de-talentos" ref={sectionRef}>
      <div className="m-auto text-center max-w-3xl">
        <Text variant="h3" className="text-white">
          Cadastre seu currículo no banco de talentos
        </Text>
        <Text variant="subtitle1" className="text-white mt-2 font-normal md:hidden">
          Estamos sempre em busca de novos talentos. Cadastre seu currículo e receba vagas aderentes ao seu
          perfil.
        </Text>
        <Text variant="subtitle1" className="text-white mt-2 font-normal hidden md:block">
          Não encontrou a vaga desejada? Cadastre em nosso banco de talentos e aumente as suas chances de uma
          candidatura em nossos processos seletivos.
        </Text>
        {loading ? (
          <Button
            className="justify-center w-full max-w-xs h-9 max-h-14 m-auto mt-10"
            disabled
            onClick={onApply}
            data-cy="button-apply-talent-bank-loading"
            isLoading
          />
        ) : (
          <Button
            className={`max-w-xs text-[14px] h-9 m-auto mt-10 bg-${
              company?.colorCompany ? `[${company?.colorCompany}]` : 'secondary'
            }`}
            disabled={hasApplied}
            onClick={onApply}
            onTouchStart={onApply}
            data-cy="button-apply-talent-bank"
            text={hasApplied ? 'Seu currículo já está cadastrado' : 'Cadastre seu currículo'}
          />
        )}
      </div>
    </section>
  );
};
