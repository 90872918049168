import { useRouter } from 'next/router';

import { useEffect, useState } from 'react';
import { Text, Icon, Box } from '@solides/management-util-ui';

import { CandidacyProvider } from 'contexts';
import { formatTextRich } from 'utils/format';
import { Badges, Button } from 'components/atoms';
import { getDateDistanceToNow, getDateInLongText } from 'utils/date';

import useStore from 'store';
import { getVacancySalary } from 'utils/vacancy';

import { IVacancyCardProps } from './types';
import cookies from 'utils/cookies';
import { useAddress } from 'hooks';

const DESCRIPTION_MAX_LENGTH = 107;

export const VacancyCard = ({ vacancy, applications }: IVacancyCardProps): JSX.Element => {
  const router = useRouter();
  const { isAuth } = useStore(store => store.session);
  const [description, setDescription] = useState<string>('');

  const isAppliedVacancy = applications?.includes(vacancy.id);

  const isClosed = vacancy.currentState === 'concluido';

  const { showLocation, simpleAddressWithCountry } = useAddress(vacancy);

  useEffect(() => {
    const formattedDescrition = formatTextRich(vacancy.description);
    setDescription(formattedDescrition.join(' ').slice(0, DESCRIPTION_MAX_LENGTH));
  }, [vacancy]);

  const redirectVacancyDetail = () => {
    const vacancyUrl = `/vaga/${vacancy.id}`;
    if (window.location.hash) window.history.replaceState('', document.title, vacancyUrl);

    cookies.remove('vacancy_detail');
    const path = isAuth ? '/' : '';
    cookies.set('origin_router', path, { domain: 'solides.com.br', expires: 0.01 });

    router.push(vacancyUrl);
  };
  const vacancySalary = getVacancySalary(vacancy.salary);

  return (
    <CandidacyProvider vacancy={vacancy}>
      <Box
        className="flex flex-col gap-y-4 p-6 h-full relative rounded-lg overflow-hidden"
        data-cy={vacancy.requiredFields?.length ? 'vacancy-mandatory' : 'vacancy-no-mandatory'}
      >
        {isAppliedVacancy && !isClosed && (
          <span
            className="absolute top-0 left-0 w-full bg-success/20 py-2 text-center text-[12px] text-success-700"
            data-cy="already-applied-card"
          >
            Sua candidatura foi realizada para esta vaga
          </span>
        )}

        {isClosed && (
          <span className="absolute top-0 left-0 w-full bg-warning/20 py-2 text-center text-[12px] text-warning-700">
            Esta vaga foi encerrada pelo recrutador
          </span>
        )}

        <header className={`flex gap-x-3 ${isAppliedVacancy ? 'mt-8' : ''}`}>
          <div className="flex-1">
            <Text
              variant="subtitle2"
              title={vacancy.title}
              data-cy="vacancy-title"
              className="line-clamp-2 break-words text-gray-900"
            >
              {vacancy.title}
            </Text>
          </div>
        </header>

        <div className="flex flex-wrap items-start gap-x-6 gap-y-3 text-gray-900">
          <p className="flex items-center gap-x-[6px] text-gray-900 text-caption" data-cy="vacancy-location">
            <Icon face="location_on" size="sm" />
            {showLocation ? simpleAddressWithCountry : 'Local não informado'}
          </p>

          <p className="flex items-center gap-x-2 text-gray-900 text-caption" data-cy="vacancy-positions">
            <Icon face="business_center" size="sm" />{' '}
            {vacancy.availablePositions === 1 ? '1 posição' : `${vacancy.availablePositions} posições`}
          </p>

          <p className="flex items-center gap-x-2 text-gray-900 text-caption" data-cy="vacancy-salary">
            <Icon face="monetization_on" size="sm" /> {vacancySalary}
          </p>

          {(vacancy.pcdOnly || vacancy?.peopleWithDisabilities) && (
            <p className="flex items-center gap-x-2 text-gray-900 text-caption" data-cy="vacancy-pcd">
              <Icon face="accessibility_new" size="sm" />
              {vacancy.pcdOnly ? 'Exclusivo' : 'Elegível'} para PCD
            </p>
          )}
        </div>

        <Badges vacancy={vacancy} />

        {vacancy.createdAt && (
          <time
            data-cy="vacancy-date"
            dateTime={vacancy.createdAt}
            className="text-caption text-gray-600"
            title={'Postada em '.concat(getDateInLongText(vacancy.createdAt))}
          >
            Postada {getDateDistanceToNow(vacancy.createdAt)}
          </time>
        )}

        <Text
          variant="body2"
          data-cy="vacancy-description"
          className="break-words text-gray-900 mb-auto"
          title={description}
        >
          {description}
          {description.length === DESCRIPTION_MAX_LENGTH && '... '}
        </Text>

        {!isClosed && (
          <div className="flex flex-row gap-x-4 gap-y-4">
            <Button
              text="Ver vaga"
              className="px-5 py-3 text-[14px]"
              data-cy="vacancy-details"
              data-testid="vacancy-details"
              onClick={redirectVacancyDetail}
            />
          </div>
        )}
      </Box>
    </CandidacyProvider>
  );
};
