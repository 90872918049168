import React, { useEffect, useRef, useState } from 'react';
import { Box, Text } from '@solides/management-util-ui';
import { formatTextRich, removingRightIdent } from 'utils/format';
import { IconFacebookF, IconInstagram, IconLinkedin, IconTwitter, IconYoutube, IconGlassdoor } from 'assets';
import { ICompanyAboutWe } from './types';
import Link from 'next/link';

const icons = {
  linkedin: <IconLinkedin color="#637381" />,
  facebook: <IconFacebookF color="#637381" />,
  instagram: <IconInstagram color="#637381" />,
  youtube: <IconYoutube color="#637381" />,
  twitter: <IconTwitter color="#637381" />,
  glassdoor: <IconGlassdoor color="#637381" />,
};

export const CompanyAboutWe: React.FC<ICompanyAboutWe> = ({ company }) => {
  const [description, setDescription] = useState(null);
  const refDescription = useRef<HTMLDivElement>(null);

  const orderSocials = Object.keys(icons);

  useEffect(() => {
    setDescription(
      company?.description
        ? company?.description
        : 'Temos diversos vagas de emprego, aguardando sua candidatura.'
    );
    setDescription(removingRightIdent(company?.description));
  }, [company]);

  let subTitle = 'Quem Somos';
  let minHeightBox = 'min-h-[272px]';
  if (!company?.description && (company?.social?.length > 0 || company?.companyUrl)) {
    subTitle = 'Nos Acompanhe';
    minHeightBox = '';
  }

  return (
    <Box className={`"bg-white p-8 md:p-8 row-span-3 ${minHeightBox}"`}>
      <div ref={refDescription} className="flex flex-col gap-y-2">
        <Text variant="subtitle1">{subTitle}</Text>

        <div
          className="break-words"
          data-testid="company-about-we-container-description"
          data-cy="company-about-we-container-description"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          <span
            className="[&>ul]:list-disc [&>ul]:whitespace-normal [&>ol]:whitespace-normal whitespace-pre-wrap [&>ul]:ml-6 [&>ol]:ml-6 [&>ol]:list-decimal"
            data-cy="company-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {/* SOCIAIS */}
        <div
          className="flex flex-wrap items-center gap-x-2 sm:gap-x-[10px]"
          data-testid="company-about-we-container-social-medias"
          data-cy="company-about-we-container-social-medias"
        >
          {orderSocials.map((socialKey: string, index: number) => {
            const social = company?.social?.find(({ type }) => type === socialKey);

            if (social?.url && social?.url?.includes('https')) {
              return (
                <a
                  key={index}
                  href={social.url}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Link para rede social ${socialKey} da empresa ${company.name}`}
                >
                  {icons[social.type]}
                </a>
              );
            }

            return null;
          })}
          {company?.companyUrl && (
            <Link href={company?.companyUrl} legacyBehavior>
              <a
                key={company?.referenceId}
                className="company-url"
                target="_blank"
                rel="noreferrer"
                aria-label={`Link para site da empresa ${company.name}`}
              >
                Acessar site da empresa
              </a>
            </Link>
          )}
        </div>
      </div>
    </Box>
  );
};
