import { CreateSession } from './types';
import { KEY_USER_STORAGE } from 'utils/constants';
import { DEFAULT_USER, removeUserCookies } from 'utils/user';

import { removeApplied } from 'utils/vacancy';

export const createSession: CreateSession = (set, get) => ({
  user: DEFAULT_USER,
  loading: false,
  isAuth: false,
  signIn: userData => {
    set(draft => {
      draft.session.user = userData;
      draft.session.isAuth = !!userData?.email;
      sessionStorage.setItem(KEY_USER_STORAGE, JSON.stringify(userData));

      return draft;
    });
  },
  signOut: () => {
    set(draft => {
      draft.session.user = DEFAULT_USER;
      draft.session.isAuth = false;
      sessionStorage.removeItem(KEY_USER_STORAGE);
      removeUserCookies();

      return draft;
    });
  },
  setLoading: newStatus => {
    set(draft => {
      draft.session.loading = newStatus;

      return draft;
    });
  },
  appliedVacancy: (vacancy: number) => {
    set(draft => {
      const applications = [...get().session.user.vacancies.applications, vacancy];
      draft.session.user.vacancies.applications = applications;

      sessionStorage.setItem(KEY_USER_STORAGE, JSON.stringify(draft.session.user));

      return draft;
    });
  },
  removeAppliedVacancy: (vacancyId: number) => {
    set(draft => {
      const isSuccess = removeApplied({
        vacancyId,
        email: draft.session.user.email,
      });

      if (isSuccess) {
        let applications = get().session.user.vacancies.applications;
        applications = applications.filter(applied => applied !== vacancyId);
        draft.session.user.vacancies.applications = applications;

        sessionStorage.setItem(KEY_USER_STORAGE, JSON.stringify(draft.session.user));
      }
      return draft;
    });
  },
  updatingFavorites: (vacancy: number) => {
    set(draft => {
      let favorites = [...get().session.user.vacancies.favorites];

      if (favorites.includes(vacancy)) {
        favorites = favorites.filter(v => v !== vacancy);
      } else {
        favorites.push(vacancy);
      }

      draft.session.user.vacancies.favorites = favorites;
      sessionStorage.setItem(KEY_USER_STORAGE, JSON.stringify(draft.session.user));

      return draft;
    });
  },
});
