import { useRouter } from 'next/router';
import * as Popover from '@radix-ui/react-popover';
import React, { useEffect, useMemo, useState } from 'react';

import { Text, Icon } from '@solides/management-util-ui';
import { EmptyNotifications, Notification } from 'components/atoms';

import useStore from 'store';
import { api } from 'services/api';
import { NotificationProps } from 'types/notification';

type Tab = 'reads' | 'recents';

export const Notifications = () => {
  const router = useRouter();
  const { email } = useStore(store => store.session.user);

  const [tab, setTab] = useState<Tab>('recents');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const [containerMobileHeight, setContainerMobileHeight] = useState<string>('100vh');

  useEffect(() => {
    setIsOpen(false);
    document.body.style.overflow = 'auto';
  }, [router.asPath]);

  useEffect(() => {
    const loadNotifications = async () => {
      try {
        const response = await api.get('notifications', {
          params: { email },
        });

        setNotifications(response.data.data.data);
      } catch (err) {
        console.log(err);
      }
    };

    email && loadNotifications();
  }, [email]);

  const reads = notifications.filter(item => item.readed).length;
  const recents = notifications.filter(item => !item.readed).length;

  const getClassTab = (isActive: boolean): string =>
    isActive ? 'text-primary border-b-[2px] border-b-primary' : 'text-gray-600';

  const changleVisiblePopover = isVisible => {
    setIsOpen(isVisible);
    const isHidden = isVisible && window.innerWidth <= 640;
    document.body.style.overflow = isHidden ? 'hidden' : 'auto';

    window && setContainerMobileHeight(window.innerHeight + 'px');
  };

  const onNotificationRead = async (notificationId: string) => {
    const copyNotifications = [...notifications];
    const indexNotification = copyNotifications.findIndex(n => n.id === notificationId);

    if (indexNotification !== -1) {
      try {
        copyNotifications[indexNotification].readed = true;
        setNotifications(copyNotifications);

        await api.put('notifications', { email, ids: [notificationId] });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onAllNotificationRead = async () => {
    if (recents > 0) {
      await api.put('notifications', { email, ids: [] });
      setNotifications(state => state.map(n => ({ ...n, readed: true })));
    }
  };

  const visibleNotifications = useMemo(() => {
    const isVisibleReads = tab === 'reads';
    return notifications.filter(notification => notification.readed === isVisibleReads);
  }, [tab, notifications]);

  return (
    <Popover.Root onOpenChange={changleVisiblePopover} open={isOpen} defaultOpen>
      <Popover.Trigger asChild>
        <button
          data-cy="button-open-notifications"
          className="flex items-center justify-center mr-4 relative"
        >
          {!!recents && (
            <span className="absolute w-5 h-5 top-[-7px] right-[-7px] bg-error-500 flex items-center justify-center rounded-full text-white text-caption font-semibold">
              {recents}
            </span>
          )}
          <Icon face="notifications" className="text-gray-700" variant="round" />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={`relative z-20 bg-white px-6 pt-8 sm:p-6 rounded-none sm:rounded-lg w-[100vw] sm:w-[500px] h-[${containerMobileHeight}] sm:h-auto translate-y-[-54px] sm:translate-y-0`}
          sideOffset={10}
          align="end"
          style={{
            boxShadow: '0px 0px 4px rgba(145, 158, 171, 0.24), 0px 24px 48px rgba(145, 158, 171, 0.24)',
          }}
        >
          <div className="flex items-center justify-between mb-2">
            <Text variant="h5">Notificações</Text>

            {tab === 'recents' && (
              <button
                type="button"
                disabled={recents === 0}
                data-cy="button-mark-all-reads"
                onClick={onAllNotificationRead}
                className="hidden sm:block text-[13px] text-primary font-medium disabled:text-gray-400"
              >
                Marcar todas como lida
              </button>
            )}

            <Popover.PopoverClose className="text-gray-800 block sm:hidden">
              <Icon face="clear" size="md" />
            </Popover.PopoverClose>
          </div>

          <div className="flex gap-x-10 items-center border-b-[1px] border-b-gray-500/25 text-subtitle2 font-semibold">
            <button
              type="button"
              data-cy="button-tab-recents"
              onClick={() => setTab('recents')}
              className={`py-3 ${getClassTab(tab === 'recents')}`}
            >
              Recentes ({recents})
            </button>
            <button
              type="button"
              data-cy="button-tab-reads"
              onClick={() => setTab('reads')}
              className={`py-3 ${getClassTab(tab === 'reads')}`}
            >
              Lidas ({reads})
            </button>
          </div>

          <div className="max-h-[calc(100vh-214px)] sm:max-h-[350px] overflow-auto scroll pr-3 pt-2 mt-2">
            {(tab === 'reads' && reads === 0) || (tab === 'recents' && recents === 0) ? (
              <div className="pb-8 pt-5">
                <EmptyNotifications />
              </div>
            ) : (
              <ul className="flex flex-col gap-y-4">
                {visibleNotifications.map((notification, index) => (
                  <React.Fragment key={notification.id}>
                    {index !== 0 && <hr className="border-0 border-t-[1px] border-gray-500/25" />}
                    <Notification {...notification} onNotificationRead={onNotificationRead} />
                  </React.Fragment>
                ))}
              </ul>
            )}
          </div>

          {tab === 'recents' && (
            <button
              type="button"
              disabled={recents === 0}
              onClick={onAllNotificationRead}
              data-cy="button-mark-all-reads-mobile"
              className="block sm:hidden absolute bottom-0 left-0 w-full bg-white py-7 text-[13px] text-primary font-medium disabled:text-gray-400"
              style={{
                boxShadow: '0px 0px 4px rgba(145, 158, 171, 0.24), 0px 24px 48px rgba(145, 158, 171, 0.24)',
              }}
            >
              Marcar todas como lida
            </button>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
