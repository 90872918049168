export const jobsTypeOptions = [
  { text: 'Presencial', value: 'presencial' },
  { text: 'Remoto', value: 'remoto' },
  { text: 'Híbrido', value: 'hibrido' },
];

export const jobPositionOptions = [
  { text: 'Estagiário', value: 'estagiario' },
  { text: 'Trainee', value: 'trainee' },
  { text: 'Auxiliar', value: 'auxiliar' },
  { text: 'Assistente', value: 'assistente' },
  { text: 'Analista', value: 'analista' },
  { text: 'Supervisor', value: 'supervisor' },
  { text: 'Coordenador', value: 'coordenador' },
  { text: 'Gerente', value: 'gerente' },
  { text: 'Diretor', value: 'diretor' },
];

export const senioritiesOptions = [
  { text: 'Estágio', value: 'estagio' },
  { text: 'Júnior', value: 'junior' },
  { text: 'Pleno', value: 'pleno' },
  { text: 'Sênior', value: 'senior' },
  { text: 'Especialista', value: 'especialista' },
  { text: 'Principal', value: 'principal' },
];

export const contractsTypeOptions = [
  { text: 'Estágio', value: 'estagio' },
  { text: 'Aprendiz', value: 'aprendiz' },
  { text: 'CLT (efetivo)', value: 'clt' },
  { text: 'Prestador de serviço (PJ)', value: 'pj' },
  { text: 'Temporário', value: 'temporario' },
  { text: 'Autônomo', value: 'autonomo' },
  { text: 'Freelancer', value: 'freelancer' },
  { text: 'Cooperado', value: 'cooperado' },
];

export const benefitsOptions = [
  { text: 'Adiantamento de salário', value: 'adiantamento de salario' },
  { text: 'Ajuda de custo', value: 'ajuda de custo' },
  { text: 'Assistência médica', value: 'assistencia medica' },
  { text: 'Assistência odontológica', value: 'assistencia odontologica' },
  { text: 'Auxilio babá', value: 'auxilio baba' },
  { text: 'Auxílio creche', value: 'auxilio creche' },
  { text: 'Auxílio educação', value: 'auxilio educacao' },
  { text: 'Auxílio home office', value: 'auxilio home office' },
  { text: 'Auxílio moradia', value: 'auxilio moradia' },
  { text: 'Auxílio viagem', value: 'auxilio viagem' },
  { text: 'Bonificação', value: 'bonificacao' },
  { text: 'Café da manhã', value: 'cafe da manha' },
  { text: 'Carro corporativo', value: 'carro corporativo' },
  { text: 'Cesta básica', value: 'cesta basica' },
  { text: 'Cesta de natal', value: 'cesta de natal' },
  { text: 'Clube de descontos', value: 'clube de descontos' },
  { text: 'Comissão', value: 'comissao' },
  { text: 'Convênio com Farmácia', value: 'convenio com farmacia' },
  { text: 'Empréstimo consignado', value: 'emprestimo consignado' },
  { text: 'Estacionamento', value: 'estacionamento' },
  { text: 'Ginástica laboral', value: 'ginastica laboral' },
  { text: 'Horário flexível', value: 'horario flexivel' },
  { text: 'Plano de carreira', value: 'plano de carreira' },
  { text: 'PLR', value: 'plr' },
  { text: 'Previdência Privada', value: 'previdencia privada' },
  { text: 'Refeitório', value: 'refeitorio' },
  { text: 'Seguro de vida', value: 'seguro de vida' },
  { text: 'Vale alimentação', value: 'vale alimentacao' },
  { text: 'Vale combustível', value: 'vale combustivel' },
  { text: 'Vale cultura', value: 'vale cultura' },
  { text: 'Vale refeição', value: 'vale refeicao' },
  { text: 'Vale transporte', value: 'vale transporte' },
];

export const salaryOptions = [
  { text: 'Até R$ 1.000,00', value: '999.99' },
  { text: 'A partir de R$ 1.000,00 ', value: '1000' },
  { text: 'A partir de R$ 2.000,00 ', value: '2000' },
  { text: 'A partir de R$ 3.000,00', value: '3000' },
  { text: 'A partir de R$ 4.000,00', value: '4000' },
  { text: 'A partir de R$ 5.000,00', value: '5000' },
  { text: 'Todas', value: '0' },
];

export const occupationAreaOptions = [
  { text: 'Administrativo', value: 'administrativo' },
  { text: 'Agronegócio', value: 'agronegocio' },
  { text: 'Comercial', value: 'comercial' },
  { text: 'Compras', value: 'compras' },
  { text: 'Comunicação', value: 'comunicacao' },
  { text: 'Design', value: 'design' },
  { text: 'Educação', value: 'educacao' },
  { text: 'Engenharia', value: 'engenharia' },
  { text: 'Financeiro', value: 'financeiro' },
  { text: 'Jurídico', value: 'juridico' },
  { text: 'Logística', value: 'logistica' },
  { text: 'Marketing', value: 'marketing' },
  { text: 'Primeiro emprego', value: 'primeiro-emprego' },
  { text: 'Produção', value: 'producao' },
  { text: 'Recursos Humanos', value: 'recursos-humanos' },
  { text: 'Saúde', value: 'saude' },
  { text: 'Tecnologia', value: 'tecnologia' },
  { text: 'Turismo', value: 'turismo' },
];

export const affirmativeOptions = [
  { text: 'Pessoas LGBTQIAP+', value: 'lgbt' },
  { text: 'Pessoas Pretas', value: 'pretas' },
  { text: 'Mulheres', value: 'mulheres' },
  { text: 'Indígenas', value: 'indigenas' },
];
