import React from 'react';
import { Box } from '@solides/management-util-ui';

export const VacancyCardSkeleton = (): JSX.Element => (
  <Box className="animate-pulse flex flex-col gap-y-4 p-6 h-full" role="listitem">
    <header className="flex items-center gap-x-4">
      <div className="rounded-full bg-slate-200 h-16 w-16" />

      <div className="flex flex-col gap-y-2">
        <div className="h-4 bg-slate-200 rounded w-48 sm:w-80" />
        <div className="h-4 bg-slate-200 rounded w-28 sm:w-40" />
      </div>
    </header>

    <div className="flex gap-x-2">
      <div className="h-3 bg-slate-200 rounded w-[168px]" />
      <div className="h-3 bg-slate-200 rounded w-[168px]" />
    </div>

    <div className="flex gap-x-2">
      {[1, 2, 3, 4, 5].map(item => (
        <div className="h-6 bg-slate-200 rounded w-[80px]" key={item} />
      ))}
    </div>

    <div className="flex flex-col gap-y-2 w-full mt-2">
      {[1, 2, 3, 4].map(item => (
        <div className="h-3 bg-slate-200 rounded" key={item} />
      ))}
    </div>

    <div className="flex gap-x-4 w-full mt-2">
      <div className="h-10 bg-slate-200 rounded w-full" />
      <div className="h-10 bg-slate-200 rounded w-full" />
    </div>
  </Box>
);
