import { CompanyAboutWe } from 'components/atoms';
import { VideoPlayer } from 'components/molecules';
import { ICompanyAboutWe } from './types';

import { getContentByVideo } from './styles';
import { useScrollToElement } from 'hooks';

export const BoxCompanyAboutWe: React.FC<ICompanyAboutWe> = ({ className = '', company }) => {
  const sectionRef = useScrollToElement('#quem-somos');

  return (
    <section className={className} id="quem-somos" ref={sectionRef}>
      <div className={getContentByVideo(!!company?.urlVideo)}>
        <CompanyAboutWe company={company} />

        {company?.urlVideo && (
          <div className="overflow-hidden shadow-sm shadow-gray-400 rounded-[4px]">
            <VideoPlayer
              urlVideo={company.urlVideo}
              title={`Vídeo de apresentação da empresa ${company.name}`}
            />
          </div>
        )}
      </div>
    </section>
  );
};
