import { Button, Icon, Modal, Text } from '@solides/management-util-ui';

const ReviewButton = ({ onClick }) => (
  <Button
    size="md"
    className="flex-1 justify-center"
    variant="outlined"
    onClick={onClick}
    data-cy="review-button"
  >
    Responder Depois
  </Button>
);

const SendButton = ({ onClick }) => (
  <Button
    size="md"
    color="primary"
    className="flex-1 justify-center"
    variant="contained"
    onClick={onClick}
    data-cy="send-button-review-modal"
  >
    Responder Profiler
  </Button>
);

const Title = ({ title }) => (
  <div className="flex flex-col items-center gap-y-5 w-full">
    <Icon face="warning" size="xl" variant="filled" className="text-primary text-[54px]" />
    <Text variant="h5" className="text-center">
      {title}
    </Text>
  </div>
);

export const ModalHasProfiler = ({ show, onClose, onSend, candidacyMessage, candidacyTitle }) => (
  <Modal
    size="md"
    show={show}
    onClose={onClose}
    title={<Title title={candidacyTitle} />}
    showCloseIcon={false}
    cancelButton={<ReviewButton onClick={onClose} />}
    confirmButton={<SendButton onClick={onSend} />}
  >
    <Text variant="body1" className="text-center text-gray-800 mb-6">
      {candidacyMessage}
    </Text>
  </Modal>
);
