import React from 'react';
import { Button, Icon, Modal, Text } from '@solides/management-util-ui';

const CancelButton = ({ onClick }) => (
  <Button
    size="md"
    color="primary"
    className="w-full justify-center mb-6"
    variant="contained"
    onClick={onClick}
    data-cy="modal-externalCandidacy-cancel"
  >
    Ok, entendi!
  </Button>
);

const Title = () => (
  <div className="flex flex-col items-center gap-y-5 w-full">
    <Icon face="business_center" size="xl" className="text-primary text-[54px]" />
    <Text variant="h5" className="text-center">
      Candidatura externa
    </Text>
  </div>
);

export const ModalExternalCandidacy = ({ show, onClose }) => (
  <Modal
    size="sm"
    show={show}
    title={<Title />}
    onClose={onClose}
    showCloseIcon={false}
    cancelButton={<CancelButton onClick={onClose} />}
  >
    <Text variant="body1" className="text-center text-gray-800 mb-5">
      Você não pode se candidatar a esta vaga, por ser colaborador desta empresa e a vaga trata-se de uma
      candidatura externa.
    </Text>
  </Modal>
);
