/* eslint-disable jsx-a11y/control-has-associated-label */
import { Icon } from '@solides/management-util-ui';
import { NotificationProps } from 'types/notification';
import { getDateDistanceToNow, getDateInLongText } from 'utils/date';

import { NotificationIcon } from './icon';
import { NotificationDescription } from './description';

interface Props extends NotificationProps {
  onNotificationRead: (value: string) => void;
}

export const Notification = ({
  id,
  action,
  readed,
  notificationDate,
  vacancy,
  onNotificationRead,
}: Props) => {
  const notificationTitle = {
    updateVacancy: 'Uma de suas vagas foi atualizada!',
    resendEmail: 'Foi enviado um email para você',
    closeVacancy: 'Uma de suas vagas foi encerrada!',
    phaseAdvance: 'Você passou para próxima fase!',
  };

  const handleNotificationRead = () => onNotificationRead(id);

  return (
    <li className="flex items-start gap-x-2">
      <NotificationIcon action={action} />

      <div className="text-gray-800 flex-1">
        <div className="flex items-start gap-x-3">
          <strong className="text-subtitle2">{notificationTitle[action]}</strong>

          <time
            dateTime={notificationDate}
            title={getDateInLongText(notificationDate)}
            className="block text-caption text-gray-400 ml-auto min-w-[65px] text-right"
          >
            {getDateDistanceToNow(notificationDate)}
          </time>

          {!readed && (
            <button
              type="button"
              className="flex w-4 h-4"
              title="marcar como lida"
              data-cy="button-mark-read"
              data-testid={`read-notification-${id}`}
              onClick={handleNotificationRead}
            >
              <Icon face="close" className="text-gray-600 text-[16px]" />
            </button>
          )}
        </div>

        <NotificationDescription action={action} vacancy={vacancy} />
      </div>
    </li>
  );
};
