import React from 'react';
import { Button, Icon, Modal, Text } from '@solides/management-util-ui';

const CancelButton = ({ onClick }) => (
  <Button
    size="md"
    color="primary"
    className="w-full justify-center mb-6"
    variant="contained"
    onClick={onClick}
    data-cy="modal-notAceptableCandidacy-cancel"
  >
    Ok, entendi!
  </Button>
);

const Title = ({ title }) => (
  <div className="flex flex-col items-center gap-y-5 w-full">
    <Icon face="error" size="xl" className="text-error text-[54px]" />
    <Text variant="h5" className="text-center">
      {title}
    </Text>
  </div>
);

export const ModalPCD = ({ show, onClose }) => (
  <Modal
    size="sm"
    show={show}
    title={<Title title="Não foi possível se candidatar" />}
    onClose={onClose}
    showCloseIcon={false}
    cancelButton={<CancelButton onClick={onClose} />}
  >
    <Text variant="body1" className="text-center text-gray-800 mb-5">
      Essa é uma vaga destinada apenas a PCD (Pessoas com Deficiência). Portanto não é possível se candidatar
    </Text>
  </Modal>
);
