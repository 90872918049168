import { SVGNotifications } from './svg/notifications';

export const EmptyNotifications = () => (
  <div className="flex flex-col items-center gap-y-2 max-w-[312px] text-center mx-auto">
    <SVGNotifications />

    <h1 className="text-h5 text-gray-800 font-bold">Parece que você já leu todas as notificações</h1>

    <p className="text-gray-600 text-body1">
      Fique atento a suas notificações para não perder nenhuma novidade das suas candidaturas.
    </p>
  </div>
);
