import { Text, Icon } from '@solides/management-util-ui';
import { Bullet } from 'components/atoms';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';
import OutsideClickHandler from 'react-outside-click-handler';
import { IFieldSingleLocationProps, ILocation } from './types';

export const FieldSingleLocation = ({
  locations = [],
  locationsSelected = [],
  onChange,
  label = '',
  colorCompany,
  colorTextCompany,
}: IFieldSingleLocationProps) => {
  const router = useRouter();
  const [active, setActive] = useState<boolean>(false);
  const contentHeight = useMemo(() => (active ? ' max-h-[700px] z-10' : ' max-h-12'), [active]);

  useEffect(() => {
    setActive(false);
  }, [router.asPath]);

  useEffect(() => {
    if (locationsSelected.length === 0) locationsSelected.push('Todos');
  }, [locationsSelected]);

  const handleToggle = (item: ILocation) => {
    onChange(item);
    setActive(false);
  };

  const isSelected = useCallback(
    location => {
      const classes = ['p-3 hover:bg-gray-200 rounded-lg cursor-pointer'];
      if (locationsSelected.includes(location.name)) classes.push('bg-gray-200');

      return classes.join(' ');
    },
    [locationsSelected]
  );

  return (
    <>
      {label && (
        <label
          htmlFor="title-field"
          className="flex text-font-color-primary focus-within:text-primary mb-1"
          data-testid="test-component-textfield-label-container"
        >
          <p className="font-body text-body2 font-regular " data-testid="test-component-textfield-label">
            {label}
          </p>
        </label>
      )}
      <OutsideClickHandler onOutsideClick={() => setActive(false)}>
        <div
          className="w-full h-12 relative"
          style={{
            marginBottom: '3px',
          }}
        >
          <div
            data-cy="local-field-content"
            data-testid="local-field-content"
            className={`absolute top-0 left-0 w-full bg-white border rounded-lg border-gray-500/[.32] hover:border-gray-800 overflow-hidden transition-all duration-500 ${contentHeight}`}
          >
            <button
              type="button"
              data-cy="local-field-button"
              data-testid="local-field-button"
              onClick={() => setActive(state => !state)}
              className="px-5 w-full h-12 flex items-center gap-x-1"
            >
              <Icon face="location_on" variant="outlined" className="text-gray-500" />

              {locationsSelected.length ? (
                <Bullet colorCompany={colorCompany} name={locationsSelected[0]} disableRemove />
              ) : (
                <Text variant="body2" className="text-gray-500">
                  Cidade
                </Text>
              )}

              {locationsSelected.length > 1 && (
                <Bullet
                  colorCompany={colorCompany}
                  name={`+ ${locationsSelected.length - 1}`}
                  className="px-2"
                  disableRemove
                />
              )}

              <Icon
                face="keyboard_arrow_down"
                className={'ml-auto transition duration-300 '.concat(active ? '-rotate-180' : '')}
              />
            </button>
          </div>
          <div
            className={`absolute top-14 left-0 z-20 p-2 pr-1 bg-white rounded-lg shadow-lg w-full h-auto flex-col  ${
              active ? 'flex' : 'hidden'
            }`}
          >
            <ul className="overflow-y-auto max-h-[200px] p-3 scroll-field-single-location">
              {[{ name: 'Todos' }, ...locations]?.map(item => (
                <li
                  key={item.name}
                  aria-hidden
                  className={isSelected(item)}
                  onClick={() => handleToggle(item)}
                >
                  <Text variant="body2" className="text-black">
                    {item.name}
                  </Text>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};
