import { createContext, useEffect, useMemo, useState } from 'react';
import { getCompanyData } from 'utils/getCompanyData';
import { ModalFeatureToggle } from 'components/atoms';
import { fetchFeatures, featuresToggle } from 'services/firebase';
import useStore from 'store';

export interface ICompanyContextProps {
  colorCompany: string;
  colorTextCompany: string;
  isLoadingCompany: boolean;
  isNotColorCompany: boolean;
}

export interface IPropsChildren {
  children: React.ReactNode;
  slug: string;
}

const CompanyContext = createContext({} as ICompanyContextProps);

function CompanyProvider({ slug, children }: IPropsChildren) {
  const { setFeature } = useStore(store => store.featuresToggle);

  const cookieColorCompany = localStorage.getItem('colorCompany') || '';
  const cookieColorTextCompany = localStorage.getItem('colorTextCompany') || '';
  const cookieSlugCompany = localStorage.getItem('slugCompany') || '';
  const [color, setColor] = useState<string>();
  const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(false);
  const [features, setFeatures] = useState<{ home_showModal?: string }>({});
  const colorCompany = cookieColorCompany || color;
  const colorTextCompany = cookieColorTextCompany || color;

  const values = useMemo(
    () => ({ colorCompany, colorTextCompany, isLoadingCompany, isNotColorCompany: !cookieColorCompany }),
    [colorCompany, isLoadingCompany, cookieColorCompany]
  );

  useEffect(() => {
    fetchFeatures().then(items => {
      if (items) {
        items.forEach(setFeature);
        setFeatures(featuresToggle);
      }
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (slug) {
        if ((!cookieColorCompany && slug) || cookieSlugCompany !== slug) {
          setIsLoadingCompany(true);
          try {
            const response = await getCompanyData(slug);
            localStorage.setItem('colorCompany', response?.colorCompany);
            localStorage.setItem('colorTextCompany', response?.colorTextCompany);
            localStorage.setItem('slugCompany', response?.slug);
            setColor(response?.colorCompany);
          } catch (error) {
            console.error('Context company, Erro ao buscar dados da empresa:', error);
          } finally {
            setIsLoadingCompany(false);
          }
        } else {
          setColor(cookieColorCompany);
        }
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <CompanyContext.Provider value={values}>{children}</CompanyContext.Provider>

      <ModalFeatureToggle data={JSON.parse(features?.home_showModal || '{}')} />
    </>
  );
}

export { CompanyProvider, CompanyContext };
