import { api } from 'services/api';
import { getPlatformPrefix, IPlatformPrefix } from './getPlatformPrefix';

export async function getPublisherInfo(appContext: IPlatformPrefix) {
  const slug = getPlatformPrefix(appContext);
  try {
    if (!slug) throw new Error();

    const { data } = await api.get(`/home/company/${slug}`);

    if (typeof data !== 'object') throw Error();
    return data?.data || data;
  } catch (error) {
    throw new Error(`Empresa não encontrada: ${slug}`);
  }
}
