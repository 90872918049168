import { Button, Icon, Modal, Text } from '@solides/management-util-ui';
import useStore from 'store';

const Title = () => (
  <div className="flex flex-col items-center gap-y-5 w-full">
    <Icon face="check_circle" size="xl" variant="filled" className="text-success text-[54px]" />
    <Text variant="h5" className="text-center">
      Tudo certo!
    </Text>
  </div>
);

export const ModalHasAppliedTalentBank = ({ show, onClose }) => {
  const { company } = useStore(store => store.company);

  return (
    <Modal
      size="sm"
      show={show}
      title={<Title />}
      onClose={onClose}
      showCloseIcon={false}
      confirmButton={
        <Button
          size="lg"
          color="primary"
          className="flex-1 justify-center mb-3"
          variant="contained"
          onClick={onClose}
          data-cy="modal-appliedTalentBank-acept"
        >
          Aceitar
        </Button>
      }
    >
      <Text variant="body1" className="text-center text-gray-600 mb-5">
        Você já estava cadastrado no banco de talentos da {company?.name}.
      </Text>
    </Modal>
  );
};
