import { Text, Button } from '@solides/management-util-ui';
import { Bullet } from 'components/atoms';

interface SelectedLocationsProps {
  locations: string[];
  removeLocation: (location: string) => void;
  clearLocations: () => void;
  onFocusTab?: () => void;
}

export const SelectedLocations = ({
  locations,
  removeLocation,
  clearLocations,
  onFocusTab,
}: SelectedLocationsProps) => (
  <div className="flex flex-col gap-y-1">
    <div className="flex items-center justify-between">
      <Text variant="body2">Locais selecionados</Text>

      <Button
        type="button"
        color="primary"
        onClick={clearLocations}
        variant="text"
        data-cy="local-field-clear-button"
        disabled={locations.length === 0}
        onFocus={onFocusTab}
      >
        Limpar
      </Button>
    </div>

    <div className="flex flex-row flex-wrap gap-2 max-h-[162px] overflow-y-auto">
      {locations.length ? (
        locations.map(location => (
          <Bullet name={location} onRemove={() => removeLocation(location)} key={location} />
        ))
      ) : (
        <Bullet name="Nenhum local selecionado" disabled />
      )}
    </div>
  </div>
);
